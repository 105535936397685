import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { defaultFonts, primaryColor } from "../../../theme";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../hook";
import { openEditUserForm, deleteUser } from "../../../reducers/myAccount";
import { User } from "../../../interfaces/backend";
import ConfirmationDialog, { ConfirmationContent } from "../../common/Dialogs/ConfirmationDialog";
import { useConfirmationDialog } from "./userHelper";

interface UserManagementButtonsProps {
  user: User;
}


const UserManagementButtons = (props: UserManagementButtonsProps) => {

   const { t } = useTranslation("myAccount");
  const dispatch = useAppDispatch();

  const {user} = props;

  const styles = {
    width: "100%",
    fontWeight: 400,
    fontSize: defaultFonts,
    marginY: "5px",
    borderRadius: "1.065px",
  };

  const {
    isConfirmationOpen,
    handleDeleteIconClick,
    closeConfirmationDialog,
    onConfirm,
  } = useConfirmationDialog();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "flex-end",
        mt: '10px'
      }}
    >
      <Button
        variant={"contained"}
        onClick={() => dispatch(openEditUserForm(user))}
        sx={{ ...styles }}
      >
        {t("editUser")}
      </Button>

      <Button
        variant={"outlined"}
        onClick={() =>
          handleDeleteIconClick(user.id)
        }
        sx={{
          ...styles,
          color: primaryColor.whiteText,
        }}
      >
        {t("deleteUser")}
      </Button>

      {isConfirmationOpen && 
            
            <ConfirmationDialog
                onConfirm={onConfirm}
                handleCancel={() => {
                    closeConfirmationDialog();
                }}
                modalContent={
                <ConfirmationContent dataContent={t("sureToDeleteUser")} />
                }
            />
            }
    </Box>
  );
};

export default UserManagementButtons;