import {put, takeEvery} from 'redux-saga/effects';
import {callHttp, callHttpWithoutSpinner} from '../utils/api';
import {del, get, patch, post} from '../utils/httpUtil';
import {
  CART,
  CART_ITEMS,
  CART_ITEMS_CODE,
  CHECKOUT_DOCUMENTS,
  CHECKOUT_DOCUMENTS_FILE,
  CHECKOUT_PAYMENT_METHOD,
  CHECKOUT_SHIPPING_ADDRESS,
  CHECKOUT_SHIPPING_OPTION,
  ORDER_OPTION,
  SET_CHECKOUT_ORDER_OPTION,
  TERMS,
} from '../constants/api';
import {openErrorSnack, openSuccessSnack} from '../reducers/start'
import {Cart, OrderOption, Term} from "../interfaces/backend";
import {
  getCart,
  getCheckoutCart,
  setCart,
  setCartShippingAddress,
  setCheckoutCart,
  setComment,
  setInternalOrderNumber,
  setOrderOptions,
  setTerms
} from "../reducers/cart";
import {
  addOrderOptionAction,
  addPaymentMethodAction,
  addShippingAddressAction,
  addShippingOptionAction,
  deleteAttachmentAction,
  deleteCartItemAction,
  getTermsAction,
  updateCartItemAction,
  updateCartMultipleItemsAction,
  uploadAttachmentsAction,
} from "../interfaces/actions";
import i18next from "i18next";

i18next.loadNamespaces(['messages']);


export function* getTerms(action: getTermsAction) {
  try {
    const terms: Array<Term> = yield callHttp(get, TERMS),
      category = action.payload.category,
      chosenTerms: Array<Term> = terms.filter((term) => term.category === category)
    yield put(setTerms(chosenTerms))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* getCartSaga() {
  try {
    // const cart = !localStorage.getItem("technician")&& localStorage.getItem("token") ? yield callHttp(get, CART):[]
    const cart: Cart = yield callHttp(get, CART)
    yield put(setCart(cart))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* deleteCartItem(action: deleteCartItemAction) {
  try {
    const {code} = action.payload
    yield callHttp(del, CART_ITEMS_CODE(code));
    const cart: Cart = yield callHttp(get, CART);

    if (cart.items === undefined) {
      // localStorage.removeItem('comment');
      // localStorage.removeItem('internalOrderNumber');
      yield put(setComment(''))
      yield put(setInternalOrderNumber(''))
    }
    yield put(setCart(cart))
    yield put(getCheckoutCart())
    yield put(openSuccessSnack(i18next.t('messages:Removed from cart')));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* updateCartItem(action: updateCartItemAction) {
  try {
    const {code, quantity} = action.payload
    const cart: Cart = yield callHttp(patch, CART_ITEMS_CODE(code), {quantity})
    yield put(setCart(cart))
  } catch (err: any) {
    yield put(getCart())
    yield put(openErrorSnack(err.message))
  }
  yield put(getCheckoutCart())
}


export function* addCartItem(action: updateCartItemAction) {
  try {
    i18next.loadNamespaces('messages');
    const {code, quantity} = action.payload
    const cart: Cart = yield callHttp(post, CART_ITEMS, [{product: code, quantity}])
    yield put(setCart(cart))
    yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
  yield put(getCheckoutCart())
}

export function* addCartMultipleItems(action: updateCartMultipleItemsAction) {
  try {
    const {items} = action.payload
    const cart: Cart = yield callHttp(post, CART_ITEMS, items)
    yield put(setCart(cart))
    yield put(openSuccessSnack(i18next.t('messages:Added in cart')));
  } catch (err: any) {
    yield put(getCart())
    yield put(openErrorSnack(err.message))
  }
}


export function* addShippingOption(action: addShippingOptionAction) {
  try {
    const id = action.payload.id;
    const checkoutCart: Cart = yield callHttp(post, CHECKOUT_SHIPPING_OPTION, {
      id: parseInt(id, 10),
    });
    yield put(setCheckoutCart(checkoutCart));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* addPaymentMethod(action: addPaymentMethodAction) {
  try {
    const method = action.payload.method
    const checkoutCart: Cart = yield callHttp(post, CHECKOUT_PAYMENT_METHOD, {
      id: parseInt(method, 10),
    });
    yield put(setCheckoutCart(checkoutCart))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* addCartShippingAddress(action: addShippingAddressAction) {
  try {
    let shippingAddress = action.payload.shippingAddress

    let newAddress = (({
                         title,
                         firstName,
                         lastName,
                         companyName,
                         streetName,
                         streetNumber,
                         additional,
                         postalCode,
                         city,
                         country
                       }) => ({
      title, firstName, lastName, companyName,
      streetName, streetNumber, additional, postalCode, city, country
    }))(shippingAddress);

    const checkoutCart: Cart = yield callHttp(post, CHECKOUT_SHIPPING_ADDRESS, newAddress);
    yield put(setCartShippingAddress())
    yield put(setCheckoutCart(checkoutCart))
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getOrderOptions() {
  try {
    const options: Array<OrderOption> = yield callHttp(get, ORDER_OPTION);

    yield put(setOrderOptions(options))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* addCartOrderOption(action: addOrderOptionAction) {
  try {
    const cart: Cart = yield callHttp(post, SET_CHECKOUT_ORDER_OPTION, action.payload)
    yield put(setCheckoutCart(cart))

  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* uploadAttachments(action: uploadAttachmentsAction) {
  try {
    const {attachedFiles} = action.payload
    let formData = new FormData()

    if (attachedFiles.length > 0) {
      attachedFiles.map((file: string | Blob) => formData.append('documents[][file]', file));
    }

    const checkoutCart: Cart = yield callHttpWithoutSpinner(post, CHECKOUT_DOCUMENTS, {
      formData,
      multipart: true,
    })
    // yield put(getCart())
    yield put(setCheckoutCart(checkoutCart));

  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* deleteAttachment(action: deleteAttachmentAction) {
  try {
    yield callHttp(del, CHECKOUT_DOCUMENTS_FILE(action.payload.file))
    yield put(getCheckoutCart())

  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}


export default function* cartSaga() {
  yield takeEvery('cart/getTerms', getTerms)
  yield takeEvery('cart/getCart', getCartSaga)
  yield takeEvery('cart/deleteCartItem', deleteCartItem)
  yield takeEvery('cart/updateCartItem', updateCartItem)
  yield takeEvery('cart/addCartItem', addCartItem)
  yield takeEvery('cart/addCartMultipleItems', addCartMultipleItems)
  yield takeEvery('cart/addShippingOption', addShippingOption)
  yield takeEvery('cart/addPaymentMethod', addPaymentMethod)
  yield takeEvery('cart/addCartShippingAddress', addCartShippingAddress)
  yield takeEvery('cart/getOrderOptions', getOrderOptions)
  yield takeEvery('cart/addCartOrderOption', addCartOrderOption)
  yield takeEvery('cart/deleteAttachment', deleteAttachment)
  yield takeEvery('cart/uploadAttachments', uploadAttachments)
}
