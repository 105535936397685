import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {addPaymentMethod, getPaymentMethods} from "../../reducers/cart";

import RadioGroup from "./RadioGroup";


export default function PaymentMethods() {

    const dispatch = useAppDispatch()


    useEffect(() => {
        dispatch(getPaymentMethods())
    }, [])
    const paymentMethods = useAppSelector(state => state.cart.paymentMethods)

    const selectedMethod = useAppSelector(state => state.cart.checkoutCart.paymentMethod )

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        dispatch(addPaymentMethod({method: event.target.value}))
    }

    return (
        <RadioGroup
            options ={paymentMethods}
            value={selectedMethod?.id}
            handleChange={handleChange}
        />
    )
}
