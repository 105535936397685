import { Grid, Box } from "@mui/material";

import { useTranslation } from "react-i18next";
import { primaryColor } from "../../theme";
import CheckoutOptions from "../../pages/Checkout/CheckoutOptions";
import CheckoutSummary from "../../pages/Checkout/CheckoutSummary";
import CostArea from "../../pages/Checkout/CheckoutSummary/CostArea";
import VoucherArea from "../../pages/Checkout/CheckoutSummary/VoucherArea";

interface CheckOutComponents {
  handleClick: any;
  enableBuyButton: any;
  checkoutCart: any;

}

const CheckOutComponents = (props: CheckOutComponents) => {
  const {
    handleClick,
    enableBuyButton,
    checkoutCart,
  } = props;
  return (
    <Box
      sx={{
        color: primaryColor.whiteText,
      }}
    >
      <Box
        sx={{
          mt: 0,
          mb: "15px",
        }}
      >
        <CheckoutSummary
          handleClick={handleClick}
          enableBuyButton={enableBuyButton}
          checkoutCart={checkoutCart}
        />
      </Box>
      <Box
        sx={{
          display: { md: "none" },
        }}
      >
        <VoucherArea />
      </Box>

      <Box>
        <CheckoutOptions
          handleClick={handleClick}
          enableBuyButton={enableBuyButton}
          checkoutCart={checkoutCart}
        />
      </Box>

      <Box
        sx={{
          display: { md: "none" },
          padding: {
            xs: "0px 40px",
            md: 0,
          },
        }}
      >
        <CostArea
          checkoutCart={checkoutCart}
          handleClick={handleClick}
          enableBuyButton={enableBuyButton}
        />
      </Box>
    </Box>
  );
};

export default CheckOutComponents;
