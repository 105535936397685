import {Box, Container, Typography, useMediaQuery} from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import theme, {primaryColor} from "../../../theme";
import SelectLanguage from "./selectLanguage";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {MenuItem, SettingElement} from "../../../interfaces/backend";
import React, {useEffect} from "react";
import {getMenu, setHeaderMenu} from "../../../reducers/static";
import ServiceHeader from "./ServiceHeader";

const TopHeader = () => {
    const StyledLink = (linkContent: MenuItem, index: string | number) => {
        const {title, link, isBlank} = linkContent

        return <Box
            key={index}
            component="a"
            href={link}
            target={isBlank ? "_blank" : '_self'}
            // sx={{
            //     color: primaryColor.textHeader,
            //     textDecoration: 'none',
            //     fontWeight: 400,
            //     '&:hover': {
            //         color: primaryColor.whiteText
            //     }
            // }}
        >
            <Typography display='' variant='subtitle2' color={primaryColor.whiteText}>{title}</Typography>
        </Box>
    }

    const dispatch = useAppDispatch()
    const headerLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.headerMenu)
    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)
    const phone = settings.find(item => item.name === 'contact_phone' && item.value);
    useEffect(() => {
        !headerLinks.length && dispatch(getMenu({type: 'top_menu', handler: setHeaderMenu}))
    }, [dispatch, headerLinks])
    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    return (
        <Container maxWidth={'2xl'}>
            <Box paddingY={3}
                 display={'flex'}
                 justifyContent="end"
                 alignItems="center"
                 columnGap={4}
            >
            {serviceUserEmail && <ServiceHeader />}

            {!mobileView && 
            <>
                {
                    headerLinks.map((item, index) =>
                        StyledLink(item, index)
                    )
                }

                {phone?.value && <Box
                    display={'flex'}
                    justifyContent="end"
                    alignItems="center"
                >
                    <LocalPhoneOutlinedIcon
                        sx={{height: 15, color: primaryColor.textTopHeader}}></LocalPhoneOutlinedIcon>

                    <Typography variant='subtitle2' color={primaryColor.whiteText}>
                        <a href={`tel:${phone.value}`}>{phone.value}</a>
                    </Typography>
                </Box>}

                <SelectLanguage mobileView={false}/>
            </>
            }
            </Box>
        </Container>
    )
}

export default (TopHeader)
