import { Box, Button, DialogActions, Grid, Typography } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import CommonDialog from "./commonDialog";
import { defaultFonts, primaryColor } from "../../../theme";
import { useTranslation } from "react-i18next";
 

export const ConfirmationContent = (props: {
  dataContent: string;
}): ReactElement => {
  const { dataContent } = props;
  return (
    <Typography variant="h6">
      {dataContent}
    </Typography>
  );
};


export interface ConfirmationDialogProps {
  handleCancel: Function;
  onConfirm: Function;
  modalContent: ReactElement;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { handleCancel, onConfirm, modalContent } = props;
  const { t } = useTranslation("common");
  const buttonsStyle = {
    width: "100%",
    fontSize: defaultFonts,
    marginY: "5px",
    cursor: "pointer",
  };

  return (
    <CommonDialog
      title={t("cancellationConfirmation")}
      closeState={() => handleCancel()}
    >
      {modalContent}
      <DialogActions sx={{ paddingY: "25px", paddingX: 0,
     }}>
        <Grid container >
          <Grid key={1} xs={6} item>
            <Button
              onClick={() => handleCancel()}
              sx={{
                color: primaryColor.linkColor,
                "&:hover": {
                  color: primaryColor.badgeColor,
                },
                justifyContent: "flex-start",
                paddingX: 0,
                ...buttonsStyle,
              }}
            >
              {t("not")}
            </Button>
          </Grid>
          <Grid key={2} xs={6} item>
            <Button
              variant="contained"
              onClick={() => onConfirm()}
              sx={buttonsStyle}
            >
              {t("yes")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </CommonDialog>
  );
};

export default ConfirmationDialog;