import React, {useState} from "react";
import {
    Box,
    BoxProps,
    styled,
    Typography,
    TypographyProps
} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import {Discount, DiscountValueType} from "../../../interfaces/backend";
import {removeCheckoutVoucher} from "../../../reducers/cart";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../hook";
import {formatMoney} from "../../../utils/localization";
import {checkEmptyTag} from "../../../utils/productFunctions";


export const ContainerBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '18px',
    position: 'relative',
    maxWidth: '371px',
    marginRight: '10px',
    [theme.breakpoints.down("md")]:{
        marginRight: '0px',
    }
}));
export const VoucherControlBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    [theme.breakpoints.down("md")]:{
        justifyContent: 'space-between',
    }
}));

export const OverlayInfoBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    backgroundColor: primaryColor.grayBackgroundColor,
    padding: '11px 7px',
    position: 'absolute',
    minWidth: '400px',
    marginLeft: '-7px',
    marginTop: '-11px',
    zIndex: 2
}));
export const TopBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    alignItems: 'center'
}));
export const BottomBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    marginLeft: '36px',
    marginTop: '-1px',
    fontWeight: 400,
    fontSize: '1.2rem'
}));

interface WhiteIconBoxProps extends  BoxProps {
    openInfoBox?: boolean
}

export const WhiteIconBox = styled((props: WhiteIconBoxProps) => (
    <Box {...props}
         component={'img'}
         src={props.openInfoBox ? '/images/icons/infoIcon.svg' : '/images/icons/infoIconCheckout.svg' }
    />
))(({theme}) => ({}));



export const StyledTypography = styled((props: TypographyProps) => (
    <Typography {...props} variant={'h15'}/>
))(({theme}) => ({
    marginLeft: '9px',
    marginRight: '4px',
    color: primaryColor.discountCheckoutPage,
    fontWeight: 400,
    [theme.breakpoints.down("md")]:{
        marginLeft: '0px',
        marginRight: '80px',
    }
}));

export const StyledTypographyH12 = styled((props: TypographyProps) => (
    <Typography {...props} variant="h12"/>
))(({theme}) => ({}));


interface VoucherInfoProps {
    setDiscountCode: React.Dispatch<React.SetStateAction<string>>,
    discount: Discount
}

export default function VoucherInfo({discount, setDiscountCode}: VoucherInfoProps) {

    const {t} = useTranslation('checkoutPage')
    const dispatch = useAppDispatch()
    const removeVoucher = (code: string) => {
        dispatch(removeCheckoutVoucher({code}))
        setDiscountCode('')
    };

    const [openInfoBox, setOpenInfoBox] = useState(false)

    return (

        <ContainerBox>
            <VoucherControlBox>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{position: 'relative', display: 'flex'}}>
                        <WhiteIconBox
                            onMouseOver={() => checkEmptyTag(discount.description) && setOpenInfoBox(true)}
                            onMouseOut={() => setOpenInfoBox(false)}
                            sx={{
                                visibility: checkEmptyTag(discount.description) ? 'visible' : 'hidden',
                                [theme.breakpoints.down("md")]:{
                                    marginRight: '10px'
                                }
                            }}
                            onClick={() => setOpenInfoBox(true) }
                        />
                        {openInfoBox ?
                            <OverlayInfoBox
                                onMouseOver={() => setOpenInfoBox(true)}
                                onMouseOut={() => setOpenInfoBox(false)}
                            >
                                <TopBox>
                                    <WhiteIconBox sx={{marginRight: '11px'}} openInfoBox = {openInfoBox}/>
                                    <StyledTypographyH12 fontWeight="400">
                                        {discount.discountCode + ' (' + (discount.valueType === DiscountValueType.Percentage ? -discount.value + '%' : formatMoney(-discount.value)) + ')'}
                                    </StyledTypographyH12> </TopBox>
                                <BottomBox>
                                    <Box dangerouslySetInnerHTML={{__html: discount.description}}/>
                                </BottomBox>
                            </OverlayInfoBox>
                            : null
                        }
                    </Box>

                    <StyledTypography sx={{}}>{discount.discountCode}</StyledTypography>
                </Box>
                <Typography variant='h15' sx={{cursor: 'pointer'}}
                            onClick={() => removeVoucher(discount.discountCode)}>
                    {t('delete')}
                </Typography>
            </VoucherControlBox>

        </ContainerBox>
    )
}
