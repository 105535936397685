import React, {useMemo, useState} from "react";
import {primaryColor} from "../../../theme";
import {Box, BoxProps, styled, Typography, TypographyProps} from "@mui/material";
import {useTranslation} from "react-i18next";
import {DiscountObject, DiscountValueType} from "../../../interfaces/backend";

interface DiscountInfoProps {
  discounts: DiscountObject[]
}

const ContainerBox = styled((props: BoxProps) => (
  <Box {...props}/>
))(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '6px',
  alignItems: 'center',
  cursor: 'pointer',
  color: primaryColor.whiteText,
  '&:hover': {
    color: primaryColor.orangeHoverColor
  }
}));
const IconBox = styled((props: BoxProps) => (
  <Box {...props}
       component={'img'}
       src={'/images/icons/percentageIcon.svg'}
  />
))(({theme}) => ({
  marginRight: '10px'
}));
const StyledTypography = styled((props: TypographyProps) => (
  <Typography {...props} variant={'h15'}/>
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    color: primaryColor.orangeHoverColor
  }
}));

export default function DiscountInfo({discounts}: DiscountInfoProps) {

  const [opened, setOpened] = useState(false)
  const {t} = useTranslation('checkoutPage')
  let discountInfo = useMemo(() =>
      discounts.filter((item) => item.discount.valueType === DiscountValueType.Percentage)
        .map(item => ` ${item.discount.discountCode} (${-item.discount.value}%)`).toString(),
    [discounts]);

  return (
    discountInfo.length > 0 ? <ContainerBox onClick={() => setOpened(!opened)}>
      <IconBox/>
      {!opened ?
        <StyledTypography>{t('info')}</StyledTypography>
        :
        <StyledTypography sx={{color: primaryColor.orangeHoverColor,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 3,    
          WebkitBoxOrient: 'vertical',
        }}> {discountInfo}</StyledTypography>
      }
    </ContainerBox> : <></>
  )
}