import {Box, Container, Grid, Typography} from "@mui/material";
import Logo from "../logo";
import {NavLink} from "react-router-dom";
import {primaryColor} from "../../../../theme";
import {useTranslation} from "react-i18next";
import * as React from "react";
import LeftIcons from "./leftIcons";
import ModalSearchBox from "./modalSearchBox";
import {useAppDispatch, useAppSelector} from "../../../../hook";
import { useHeaderImage } from "../../contexts/HeaderImageContext";
import { getMenu, setMainMenu } from "../../../../reducers/static";
import { useEffect } from "react";
import { MenuItem } from "../../../../interfaces/backend";
 

export default function DesktopView() {
    const showHeaderImage = useHeaderImage();
    const {t} = useTranslation('common')
    const backgroundUrl = useAppSelector(state => state.staticContent.backgroundUrl)
    const backgroundBlurred = useAppSelector(state => state.styleControls.backgroundBlurred)
    
    
    const dispatch = useAppDispatch()
    const mainMenuLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.mainMenu)

    useEffect(() => {
      !mainMenuLinks.length && dispatch(getMenu({type: 'main_menu', handler: setMainMenu}))
  }, [dispatch, mainMenuLinks])
 


    const StyledLink = (linkContent: MenuItem) => {
       const {title, link, isBlank} = linkContent
    
        return <NavLink
            to={link}
            target={isBlank ? "_blank" : '_self'}
            style={({isActive}) => ({
                color: isActive ? primaryColor.textTopHeader : primaryColor.textHeader,
                textDecoration: !isActive ? 'none' : `underline 3px ${primaryColor.textDecorationColor}`,
                textUnderlineOffset: 6,
                fontWeight: isActive ? 700 : 400
            })}>
            <Typography variant='h6'>{title}</Typography>
        </NavLink>
    }
    return (
        <>
            <Box id="bottomHeader" sx={{
                paddingY: '28px',
                backgroundColor: primaryColor.headerColor,
                zIndex: 3,
                position: 'relative'
            }}>
                <Container maxWidth={'2xl'}>
                    <Grid container
                          justifyContent='space-between'
                    >
                        <Grid item sm={2}>
                            <Logo mobileView={false}/>
                        </Grid>
                        <Grid item sm={8} container
                              alignContent='stretch'
                              alignItems='center'
                              justifyContent='space-evenly'
                              columnSpacing={1}
                              rowSpacing={{sm: 1, md: 0}}
                        >
                            {mainMenuLinks.map((item, index) =>
                                <Grid item 
                                 key={index}
                                >{StyledLink(item)}</Grid>
                            )}
                        </Grid>
                        <Grid item
                              sm={2}
                              justifyContent="end"
                              alignItems='center'
                              display='flex'
                        >
                            <Box display='flex'
                                 justifyContent='space-between'
                                 alignItems='center'
                                 color={primaryColor.whiteText}
                                 columnGap={{sm: 1, md: 2, lg: 4}}
                            >
                                <LeftIcons/>
                                <ModalSearchBox/>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            {showHeaderImage &&
            <Box sx={{
                height: '155px',
                width: '100%',
                background: `url('${backgroundUrl}') no-repeat 0% 0% / 100%`,
                filter: backgroundBlurred ? 'blur(25px)' : 'unset',
                zIndex: 1
            }}/>
        }
        </>
    )
}
