import {put, select, take, takeEvery} from 'redux-saga/effects';

import {GET_MENU_BY_ID, GET_MENU_HOMEPAGE, GET_SETTINGS, GET_SOCIAL, GET_STATIC_ITEM } from '../constants/api';

import {callHttp} from '../utils/api';
import {get} from '../utils/httpUtil';

//import {lngList} from '../utils/localization';
import i18n from '../i18n';
import {
  setBackground,
  setMenuHomePage,
  setNoImageUrl,
  setReCaptchaSiteKey,
  setSettings,
  setSocialLinks,
  setStaticPageContent
} from "../reducers/static";
import {openErrorSnack} from "../reducers/start";
import { Menu, MenuHomePage, SettingElement, SocialElement, StaticPageContent} from "../interfaces/backend";
import {getMenuAction, getStaticPageContentAction} from "../interfaces/actions";

// export function* getStickers() {
//     try {
//         const stickers = yield callHttp(get, GET_STICKERS);
//
//         yield put(setStickers(stickers));
//     } catch (err) {
//         yield put(openErrorSnack(err.message));
//     }
// }
//
export function* getMenu(action: getMenuAction) {
  const {type, handler} = action.payload;

  //FF issue, menu is called before settings are loaded
  //However this method is a problem on moving from mobile view to desktop view. Therefore, settings are called in 2 places
  let settings: Array<SettingElement> = yield select(state => state.staticContent.settings);
  if (!settings.length) {
    //this should wait until setSettings is called
    yield take('staticContent/setSettings');
    settings = yield select(state => state.staticContent.settings);
  }

  try {
    const settingsItem = settings.find(x => x.name === type && x.value);
    if (settingsItem) {
      const menu: Menu = yield callHttp(get, GET_MENU_BY_ID(settingsItem.value))
      yield put(handler(menu.items));
    }
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getSettings() {
  try {
    const settings: Array<SettingElement> = yield callHttp(get, GET_SETTINGS);
    yield put(setSettings(settings));

    //set background URL
    const background = settings.find((x: SettingElement) => x.name === 'background_image_url');
    if (background && background.value) {
      yield put(setBackground(background.value));
    }

    const noImageSettings = settings.find((x: SettingElement) => x.name === 'no_image_url');
    const noImageUrl = noImageSettings?.value || '/images/product_example_image.png'
    yield put(setNoImageUrl(noImageUrl));

    // const shop_uses_prices = settings.find(
    //     (x: SettingElement) => x.name === 'shop_uses_prices' && parseInt(x.value) === 1
    // );
    // if (shop_uses_prices) {
    //     yield put(setShopUsesPrices(true));
    // } else {
    //     yield put(setShopUsesPrices(false));
    // }

    //set reCaptcha site key
    const key = settings.find((x: SettingElement) => x.name === 'recaptcha_site_key');
    if (key && key.value) {
      yield put(setReCaptchaSiteKey(key.value));
    }

    //set languages from BE to localStorage
    const languages = settings.find((x: SettingElement) => x.name === 'languages')
    const storedLanguages = localStorage.getItem('languages');
    let needReload = false;
    if (languages && languages?.value) {
      const languagesArray: Array<string> = languages.value.split(';')
      if (storedLanguages !== languagesArray.join(',')) {
        localStorage.setItem('languages', languagesArray.join(','))
      }

      const defaultLanguage = settings.find((x: SettingElement) => x.name === 'default_language');
      const currentLanguage = localStorage.getItem('i18nextLng');

      if (defaultLanguage && defaultLanguage.value) {
        if (languagesArray.indexOf(currentLanguage as string) < 0 || sessionStorage.getItem("fallback_lng_loaded")) {
          i18n.changeLanguage(defaultLanguage.value);
          sessionStorage.removeItem("fallback_lng_loaded");
          needReload = true;
        }
      }
    }

    if (needReload) {
      window.location.reload();
    }
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getSocialLinks() {
  try {
    const social: Array<SocialElement> = yield callHttp(get, GET_SOCIAL);
    yield put(setSocialLinks(social));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getStaticPageContent(action: getStaticPageContentAction) {
  const {alias, category} = action.payload
  try {
    const staticContent: StaticPageContent = yield callHttp(get, GET_STATIC_ITEM(alias))
    yield put(setStaticPageContent(staticContent))
  } catch (err: any) {
    if (
      err.status === 303 &&
      err.message.lang === localStorage.getItem('i18nextLng')
    ) {
      window.location.href = '/' + category + '/' + err.message.alias;
    }
  }
}


export function* getMenuHomePage() {
  try {
    const menus: Array<MenuHomePage> = yield callHttp(get, GET_MENU_HOMEPAGE)
    yield put(setMenuHomePage(menus))
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}


export default function* staticSaga() {
  yield takeEvery('staticContent/getSettings', getSettings)
  yield takeEvery('staticContent/getSocialLinks', getSocialLinks)
  yield takeEvery('staticContent/getMenu', getMenu)
  yield takeEvery('staticContent/getStaticPageContent', getStaticPageContent)
  yield takeEvery('staticContent/getMenuHomePage', getMenuHomePage)
}