import {Box, BoxProps, Button, ButtonProps, styled, TextField, TextFieldProps} from "@mui/material";
import React, {FormEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import {addCheckoutVoucher} from "../../../reducers/cart";
import {useAppDispatch, useAppSelector} from "../../../hook";
import VoucherInfo from "./VoucherInfo";

const InputBox = styled((props: BoxProps) => (
  <Box {...props}/>
))(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  marginTop: '20px',
  alignItems: 'end'
}));

interface ApplyButtonProps extends ButtonProps {
  emptyCode: boolean
}

const ApplyButton = styled(({emptyCode, ...props}: ApplyButtonProps) => (
  <Button {...props} variant={"contained"} disabled={!emptyCode}/>
))(({theme}) => ({}));

const StyledTextField = styled((props: TextFieldProps) => (
  <TextField {...props}/>
))(({theme}) => ({
  marginRight: '16px',
  maxHeight: '32px',
  minWidth: '250px',
  [theme.breakpoints.down("lg")]: {
      minWidth: '230px',
  },
  [theme.breakpoints.down("md")]: {
    marginRight: '0px',
    marginBottom: '20px'
  }
}));

export default function VoucherAreaForm(props: { mobileView?: boolean }) {
  const {t} = useTranslation('checkoutPage')
  const [discountCode, setDiscountCode] = useState('')
  const dispatch = useAppDispatch()
  const checkoutCart = useAppSelector(state => state.cart.checkoutCart)
  const {mobileView} = props;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(addCheckoutVoucher({code: discountCode}))
    setDiscountCode("")
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <InputBox sx={mobileView ? {flexDirection: 'column'} : {}}>
          <StyledTextField
            onChange={handleChange}
            value={discountCode}
          />
          <ApplyButton type={'submit'} emptyCode={!!discountCode}>{t('apply')}</ApplyButton>
        </InputBox>
      </form>
      <Box sx={{display: 'flex', flexDirection: 'column', mt: '15px'}}>
        {checkoutCart.discounts.map((item, index) =>
          <VoucherInfo key={index} discount={item.discount} setDiscountCode={setDiscountCode}/>
        )}
      </Box>
    </>
  )
}