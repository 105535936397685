import {
  Box,
  Typography,
  useMediaQuery
} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import theme from "../../../theme";
import CheckoutAccordion from "../../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import VoucherAreaForm from "./VoucherAreaForm";

export default function VoucherArea() {
  const {t} = useTranslation('checkoutPage')
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  return (
    mobileView ?
      <CheckoutAccordion
        title={t('Enter action code')}
        content={
          <Box sx={{
            display: 'flex', flexDirection: 'column'
          }}>
            <VoucherAreaForm mobileView={mobileView}/>
          </Box>
        }
        mandatory={false}/>
      :
      <Box sx={{
        [theme.breakpoints.down("lg")]: {
          minWidth: '370px',
      },
        marginRight: '10px',
      }}>
        <Typography> {t('Enter action code')}</Typography>
        <VoucherAreaForm/>
      </Box>
  )
}