import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../theme";
import {useAppDispatch, useAppSelector} from "../../hook";
import React, {useEffect, useState} from "react";
import {getCheckoutCart, order} from "../../reducers/cart";
import Page from "../page";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {closeShippingAddressForm, getBillingAddress} from "../../reducers/address";
import CheckOutComponents from "../../components/checkout";
import ShippingAddressForm from "./ShippingAddressForm";

export default function CheckoutPage() {

    const dispatch = useAppDispatch()
    const {t} = useTranslation('checkoutPage')

    const navigate = useNavigate()

    const settings = useAppSelector(state => state.staticContent.settings)
    const showOrderOptions = settings && settings.find(
        (x) => x.name === 'show_order_options' && x.value !== null && parseInt(x.value) === 1
    );
    const orderCompleted = useAppSelector(state => state.cart.orderCompleted)
    const internalOrderNumber = useAppSelector(state => state.cart.internalOrderNumber)
    const checkoutCart = useAppSelector(state => state.cart.checkoutCart)
    const isOrderOptionProduct = checkoutCart?.orderOption === "product" || !showOrderOptions;
    const terms = useAppSelector(state => state.cart.terms)
    const comment = useAppSelector(state => state.cart.comment)

    const isEditMode = useAppSelector(state => state.address.isEditMode)

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))


    // const [state, setState] = useState({
    //     message: '',
    //     attachedFiles: [],
    //     showFilesInPreview: false,
    //     disabled: true,
    // })
    const [enableBuyButton, setEnableBuyButton] = useState<boolean>(false)

    const isShippingAddressFormOpened = useAppSelector(state => state.address.isShippingAddressFormOpened)

    const isInternalOrderNumberMandatory = settings.find(
        (x) => x.name === 'is_internal_order_number_mandatory' && x.value !== null && parseInt(x.value) === 1
    );


    useEffect(() => {
        dispatch(getCheckoutCart())
        dispatch(getBillingAddress())
    }, [])

    const validateForm = () => {
        let valid = true;

        if ((showOrderOptions && !checkoutCart.orderOption) ||
            (isOrderOptionProduct && !checkoutCart?.shippingMethod) ||
            !checkoutCart.paymentMethod ||
            (isInternalOrderNumberMandatory && !internalOrderNumber) ||
            (isOrderOptionProduct && checkoutCart?.shippingMethod?.isAddressRequired && !checkoutCart.shippingAddress)
        ) {
            valid = false;
        }

        terms.forEach((term) => {
            if (term.isMandatory && !term.checked) {
                valid = false
            }
        });

        return valid;
    }


    useEffect(()=> {
        setEnableBuyButton(validateForm())
    },[checkoutCart, terms, showOrderOptions, isOrderOptionProduct, isInternalOrderNumberMandatory, internalOrderNumber])
    // const validateForm = () => {
    //     let result = true;
    //     const isInternalOrderNumberMandatory = settings.find(
    //         (x) => x.name === 'is_internal_order_number_mandatory' && x.value !== null && parseInt(x.value) === 1
    //     );
    //
    //     if (showOrderOptions && !cart.orderOption) {
    //         toastr.error(t('toastMessage:error'), t('Select Order Option'));
    //         result = false;
    //     }
    //
    //     if (isOrderOptionProduct && !cart?.shippingMethod) {
    //         toastr.error(t('toastMessage:error'), t('missingShippingMethod'));
    //         result = false;
    //     }
    //
    //     // if (isOrderOptionProduct && cart?.shippingMethod?.isAddressRequired) {
    //     //     toastr.error(t('toastMessage:error'), t('confirm address'));
    //     //     result = false;
    //     // }
    //
    //     if (!cart.paymentMethod) {
    //         toastr.error(t('toastMessage:error'), t('missingPaymentMethod'));
    //         result = false;
    //     }
    //
    //     if (isInternalOrderNumberMandatory && !internalOrderNumber) {
    //         toastr.error(t('toastMessage:error'), t('missingInternalOrderNumber'));
    //         result = false;
    //     }
    //
    //     terms.forEach((term) => {
    //         if (!term.checked) {
    //             toastr.error(t('toastMessage:error'), term.errorText);
    //             result = false;
    //         }
    //     });
    //
    //     return result;
    // };

    const handleSubmitOrder = () => {
        validateForm() && dispatch(order({
                comment: comment,
                internalOrderNumber: internalOrderNumber
            })
        )
    }

    useEffect(() => {
        orderCompleted && navigate('/order-completed')
    },[orderCompleted])

    return (
        <Page>
            {!mobileView ?
                <>
                    <Typography
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            textAlign: 'center',
                            fontSize: '7rem',
                            fontWeight: 700,
                            mt: '38px', mb: '38px'
                        }}
                    >
                        {t('checkout')}
                    </Typography>

                    {checkoutCart.itemsQuantity > 0 ?
                        <CheckOutComponents 
                        handleClick= {handleSubmitOrder} 
                        enableBuyButton={enableBuyButton}
                        checkoutCart={checkoutCart}
                        />
                        :
                        <></>
                    }
                </>
                :
                !isShippingAddressFormOpened ?
                <>
                    <Typography
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            textAlign: 'center',
                            fontSize: '2.6rem',
                            fontWeight: 700,
                            marginY: '38px',
                        }}
                    >
                        {t('checkout')}
                    </Typography>

                    {checkoutCart.itemsQuantity > 0 ?
                            <CheckOutComponents 
                              handleClick= {handleSubmitOrder} 
                              enableBuyButton={enableBuyButton}
                              checkoutCart={checkoutCart}
                              />
                        :
                        <></>
                    }
                </>
                :
                    <Box sx={{
                        marginBottom: '30px',
                        marginTop: '10px'
                    }}>
                        <Box sx={{display: 'flex', paddingX: '40px', alignItems: 'center', marginBottom: '10px'}}>
                            <Box
                                component ='img'
                                src = '/images/LeftArrow_Pagination.svg'
                                sx={{
                                    cursor: 'pointer',
                                    marginRight: '40px',
                                }}
                                onClick={() => dispatch(closeShippingAddressForm())}
                            />
                            <Typography sx={{
                                fontSize: '2.6rem',
                                color: primaryColor.shoppingCartTitle,
                                wordBreak: 'break-word'
                            }}>
                                {isEditMode ? t("editShippingAddress") : t("createNewAddress")}</Typography>
                        </Box>

                        <Box
                        sx={{
                            padding: {
                                xs: '25px 50px',
                                md: "35px 35px 35px 70px",
                                lg: "35px 50px 35px 100px"
                              },
                        }}>
                        <ShippingAddressForm/>
                        </Box>
                    </Box>

            }
        </Page>
    )
}
