import {Badge, Box, Grid, Tooltip, useMediaQuery} from "@mui/material";
import {openSearchBox} from "../../../../reducers/quickSearch";
import CustomDrawer from "../customDrawer";
import {accountDrawerIcon, accountDrawerList} from "./drawerListItem";
import * as React from "react";
import {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../hook";
import {useTranslation} from "react-i18next";
import {Link, useParams} from "react-router-dom";
import {getCart} from "../../../../reducers/cart";
import MiniCart from "./MiniCart";
import {setMiniCartOpen, setMyDataOpened} from "../../../../reducers/styleControls";
import theme, {primaryColor} from "../../../../theme";
import AllTabs from "../../Tabs/AllTabs";
import {setOpenedDrawerID} from "../../../../reducers/static";
import {useAllMyAccountTabs} from "../../Tabs";

const LeftIcons = () => {
    const searchBoxOpen = useAppSelector(state => state.quickSearch.searchBoxOpen)
    const cart = useAppSelector(state => state.cart.cart)
    const dispatch = useAppDispatch()
    const {t} = useTranslation('common')
    const isMiniCartOpened = useAppSelector(state => state.styleControls.isMiniCartOpened)
    const isMiniCartOpenNeeded = useAppSelector(state => state.styleControls.isMiniCartOpenNeeded)

    const [selectedItem, setSelectedItem] = useState<string>("")
    const [selectedName, setSelectedName] = useState<string>()
    const params = useParams()

    const mobileView = useMediaQuery(theme.breakpoints.down('md'));


    const handleMouseEnter =()=>{
        dispatch(setMyDataOpened(true))
        dispatch(setOpenedDrawerID('account-menu'))
    }
 
    const handleMouseLeave = () => {
        dispatch(setMyDataOpened(false));
        dispatch(setOpenedDrawerID(''))
    };
 
    useEffect(() => {
      if(params.tab)
         setSelectedItem(params.tab);

    }, [params.tab])

  useEffect(() => {
        dispatch(getCart())
        params.tab && setSelectedItem(params.tab);
    }, [dispatch])

    return (
        <Grid container
              columnSpacing={{xs: '15px', md: '30px'}}
              height={'100%'}
              justifyContent='center'
              alignItems='center'
        >
            <Grid item alignItems='center' justifyContent={'center'} display='flex'>
                <Tooltip title={t('search')}>
                    <Box
                        component='img'
                        src={!searchBoxOpen ? '/images/whiteSearch.svg' : '/images/coloredSearch.svg'}
                        onClick={() => dispatch(openSearchBox(!searchBoxOpen))}
                        sx={{
                            cursor: 'pointer',
                            height: {xs: '19px', md: '28px'},
                            width: {xs: '19px', md: '28px'}
                        }}
                    />
                </Tooltip>
            </Grid>
            <Grid item alignItems='center' justifyContent={'center'} display='flex' sx={{position: 'relative'}}
              { ... (!mobileView && {
                onMouseLeave: handleMouseLeave, 
                 
            })}
            >
                <Tooltip title={t('account')}>
                <Box sx={{
                }} 
                { ... (!mobileView && {
                    onMouseEnter: handleMouseEnter,
                })}
                >
                    <CustomDrawer
                        icon={accountDrawerIcon}
                        additionalButtonStyle={{paddingRight: 'unset'}}
                        list={accountDrawerList}
                        element={<AllTabs allTabs={useAllMyAccountTabs()} selectedItem={selectedItem} setSelectedName={setSelectedName} selectedName={selectedName} isMobile={true} topTitle={String(t("myAccount:myAccount"))}/>}
                        id={"account-menu"}
                    />
                    </Box>
                </Tooltip>
            </Grid>
            <Grid item sx={{position: 'relative'}} onMouseLeave={() => {
                !isMiniCartOpenNeeded && dispatch(setMiniCartOpen(false))
            }}>
                <Link to={'/cart'}>
                    <Badge badgeContent={cart?.itemsQuantity}
                           slotProps={{
                               badge: {
                                   style: {
                                       fontSize: '1rem',
                                       backgroundColor: primaryColor.orangeHoverColor
                                   }
                               },
                           }}
                           color = {'primary'}
                           onMouseEnter={() => {
 
                               if (cart?.items && cart.items.length > 0 && !mobileView) {
                                   dispatch(setMiniCartOpen(true))
 
                               }

                           }
                           }
                            onClick={() => {

                            dispatch(setMiniCartOpen(false))
                    }}
                    >
                        <Tooltip title={t('cart')}>
                            <Box component={'img'}
                                 src={isMiniCartOpened ? '/images/icons/OrangeCart-icon.svg' : '/images/icons/WhiteCart-icon.svg'}
                                 sx={{
                                     height: {xs: '19px', md: '29px'},
                                     width: {xs: '19px', md: '31px'}
                                 }}
                            />
                        </Tooltip>
                    </Badge>
                </Link>
                <MiniCart/>
            </Grid>
        </Grid>
    )
}
export default React.memo(LeftIcons)