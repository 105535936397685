import {useAppDispatch, useAppSelector} from "../../../../../hook";
import {useTranslation} from "react-i18next";
import React, {useState, useEffect} from "react";
import {Box, BoxProps, Button, ButtonProps, styled, Typography, TypographyProps} from "@mui/material";
import {primaryColor} from "../../../../../theme";
import {formatMoney} from "../../../../../utils/localization";
import DesktopMiniCartTable from "./DesktopMiniCartTable";
import {useNavigate} from "react-router";
import {setMiniCartOpen} from "../../../../../reducers/styleControls";


const StyledButton = styled((props: ButtonProps) => (
    <Button {...props} variant={'contained'}/>
))(({theme}) => ({
    width: '241px',
    // maxHeight: '43px'
}));
const ContainerBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: '64px',
    right: 0,
    zIndex: 3,
    width: '752px'
}));
const MainBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: '700px',
    maxHeight: '450px',
    padding: '22px 27px 30px 27px',
    backgroundColor: primaryColor.backgroundOverlay,
    width: '100%'
}));
const LeftBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
}));
const ButtonBox = styled((props: BoxProps) => (
    <Box {...props} />
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    marginTop: '21px',
    justifyContent: 'space-between'
}));

const QuantityTypography = styled((props: TypographyProps) => (
    <Typography {...props} variant={'h20'}/>
))(({theme}) => ({
    color: primaryColor.filterText,
    fontWeight: 300,
    paddingLeft: '16px',
    textTransform: 'uppercase'
}));


export default function MiniCart() {
    const dispatch = useAppDispatch()
    const {t} = useTranslation('checkoutPage')
    const cart = useAppSelector(state => state.cart.cart)
    const navigate = useNavigate()

    const isMiniCartOpened = useAppSelector(state => state.styleControls.isMiniCartOpened)
 
   
    return (
         <ContainerBox sx = {{display: isMiniCartOpened ? 'flex' : 'none'}}>

            {/*the below empty Box is to cover the gap between Header and MiniCart, which prevent the onMouseLeave event*/}
            <Box sx={{height: '23px'}} />
            <MainBox>
                {cart.items && cart.items.length > 0 && (
                    <>
                        <Box>
                            <QuantityTypography>{cart.itemsQuantity}</QuantityTypography>
                            <QuantityTypography sx ={{color: primaryColor.shoppingCartTitle}} >{t('itemsInCart')}</QuantityTypography>
                        </Box>

                        <DesktopMiniCartTable/>

                        <Box sx={{mt: '17px', width: '100%'}}>
                            <LeftBox>
                                <Typography variant={'h20'} sx={{mr: '40px'}}>{t('productCost')}: </Typography>
                                <Typography variant={'h20'}>{formatMoney(cart.total)}</Typography>
                            </LeftBox>
                            <LeftBox>
                                <Typography variant={'h7'} sx={{mt: '5px'}}>{t('taxInfo')}</Typography>
                            </LeftBox>
                        </Box>
                        <ButtonBox>
                            <StyledButton
                                onClick={() => {
                                    dispatch(setMiniCartOpen(false))
                                    navigate('/cart')
                                }}>
                                {t('toCart')}
                            </StyledButton>
                            <StyledButton
                                onClick={() => {
                                    dispatch(setMiniCartOpen(false))
                                    navigate('/checkout')
                                }}>
                                {t('confirmation')}
                            </StyledButton>
                        </ButtonBox>
                    </>
                )
            }
            </MainBox>
        </ContainerBox>
    )
}
