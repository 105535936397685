import qs from 'qs';
import {selectLng} from '../utils/localization';
import * as settings from '../settings';

const loadUrl = (host: string, url: string) =>
    [`${host}`, selectLng(), url]
        .filter((element, index, array) => element !== '')
        .join('/');

export const BACKEND = settings.backend_api_url;

export const LOGIN = loadUrl(BACKEND, `login`);
export const REGISTER = loadUrl(BACKEND, `registration`);
export const REFRESHTOKEN = loadUrl(BACKEND, `token/refresh`);
export const PUBLICDATA = loadUrl(BACKEND, `public`);

export const FORGOTPASSWORD = loadUrl(BACKEND, `forgotpassword`);
export const RESETPASSWORD = loadUrl(BACKEND, `resetpassword`);
export const UNBLOCKACCOUNT = loadUrl(BACKEND, `account-unblock`);
export const PRODUCTS = loadUrl(BACKEND, `product`);

export const MYMACHINES = loadUrl(BACKEND, `my/machines`);
export const MYMACHINESSEARCH = (params: {}) => loadUrl(BACKEND, `my/machines/search?${qs.stringify(params, {arrayFormat: 'brackets'})}`);

export const STANDARDMACHINES = loadUrl(BACKEND, `machines/`);
export const STANDARDMACHINESSEARCH = (params: {}) => loadUrl(BACKEND, `machines/search?${qs.stringify(params, {arrayFormat: 'brackets'})}`);


export const PRODUCT = (alias: string) => loadUrl(BACKEND, `product/${alias.toLowerCase()}`);
export const PRODUCTBYCODE = (code: string) => loadUrl(BACKEND, `product/code/${code}`);
export const MACHINE = (alias: string, params: Object = {}) => loadUrl(BACKEND, `machines/${alias.toLowerCase()}?${qs.stringify(params, {arrayFormat: 'brackets'})}`);
export const PRODUCTTYPE = loadUrl(BACKEND, `productType`);
export const MACHINEBYTYPE = (alias: string) =>
    loadUrl(BACKEND, `machines/type/${alias.toLowerCase()}`);
export const MYMACHINE = (alias: string, params: Object = {}) => loadUrl(BACKEND, `my/machines/${alias.toLowerCase()}?${qs.stringify(params, {arrayFormat: 'brackets'})}`);

export const MYMACHINESENSORDATA = (alias: string, serialNumber: string) =>
    loadUrl(BACKEND, `my/machines/${alias.toLowerCase()}/item/${serialNumber}/sensor`);

export const FIND_PRODUCT = (params: {}) =>
    loadUrl(
        BACKEND,
        `product/search/filter?${qs.stringify(params, {arrayFormat: 'brackets'})}`
    );

export const FIND_QUICK = (params: {}) =>
    loadUrl(
        BACKEND,
        `product/search/quick?${qs.stringify(params, {arrayFormat: 'brackets'})}`
    );

export const SHIPPING_ADDRESS = loadUrl(BACKEND, `my/address/shipping`);
export const SHIPPING_ADDRESS_ID = (id: number) =>
    loadUrl(BACKEND, `my/address/shipping/${id}`);
export const BILLING_ADDRESS = loadUrl(BACKEND, `my/address/billing`);

export const ORDER_STATUS = loadUrl(BACKEND, `dictionary/order-status`);
export const COUNTRIES = loadUrl(BACKEND, `dictionary/country`);
export const TITLES = loadUrl(BACKEND, `dictionary/title`);

export const ME = loadUrl(BACKEND, `me`);
export const MY_ROLE = loadUrl(BACKEND, `me/role`);
export const CHANGE_PASSWORD = loadUrl(BACKEND, `me/changepassword`);

export const USERS = loadUrl(BACKEND, `my/user`);
export const USER = (id: number) => loadUrl(BACKEND, `my/user/${id}`);

export const GROUP_USERS = loadUrl(BACKEND, `my/users`);

export const GET_STATIC_ITEM = (alias: string) => loadUrl(BACKEND, `static/content/${alias.toLowerCase()}`);
export const GET_ARTICLES = (params: {}) => loadUrl(
    BACKEND,
    `static/category/article?${qs.stringify(params, {
        arrayFormat: 'brackets',
    })}`);
export const GET_STICKERS = loadUrl(BACKEND, `static/category/sticker`);
export const GET_MENU_HOMEPAGE = loadUrl(BACKEND, `static/category/homepage`)
export const GET_SOCIAL = loadUrl(BACKEND, `static/social`);

export const CART = loadUrl(BACKEND, 'carts');
export const CHECKOUT_CART = loadUrl(BACKEND, 'checkout/carts');
export const CART_DISCOUNT_DELETE = (code: string) => loadUrl(BACKEND, `checkout/discounts/${code}`);
export const CART_DISCOUNT_SET = loadUrl(BACKEND, `checkout/discounts`);

export const CART_ITEMS = loadUrl(BACKEND, 'cart-items');
export const CART_ITEMS_CODE = (code: string) => loadUrl(BACKEND, `cart-items/${code}`);

export const CART_INQUIRY = loadUrl(BACKEND, 'inquiry-carts');
export const CART_INQUIRY_ITEMS = loadUrl(BACKEND, 'inquiry-cart-items');
export const CART_INQUIRY_ITEMS_CODE = (code: string) => loadUrl(BACKEND, `inquiry-cart-items/${code}`);

export const ORDER = loadUrl(BACKEND, `orders`);
export const INQUIRY_ORDER = loadUrl(BACKEND, `inquiry-orders`);
export const FILTER_ORDER = (params: {} ) =>
    loadUrl(
        BACKEND,
        `my/orders?${qs.stringify(params, {
            arrayFormat: 'brackets',
        })}`
    );

export const CANCEL_ORDER = (orderId: string) => loadUrl(BACKEND, `my/orders/${orderId}/cancel`);

export const PUT_INQUIRY = (inquiryId: string) =>
    loadUrl(
        BACKEND,
        `my/inquiries/${inquiryId}/cancel`
    );

export const FILTER_INQUIRIES = (params: string) =>
    loadUrl(
        BACKEND,
        `my/inquiries?${qs.stringify(params, {
            arrayFormat: 'brackets',
        })}`
    );

export const USER_ORDER = loadUrl(BACKEND, `my/orders`);
export const USER_ORDER_DETAILS = (orderId: string) =>
    loadUrl(BACKEND, `my/orders/${orderId}`);
export const USER_INQUIRY_DETAILS = (inquiryId: string) =>
    loadUrl(BACKEND, `my/orders/${inquiryId}`);


export const SHIPPING_OPTION = loadUrl(BACKEND, `shipping`);
export const ORDER_OPTION = loadUrl(BACKEND, `dictionary/order-options`);
export const SET_CHECKOUT_ORDER_OPTION = loadUrl(BACKEND, `checkout/order-options`);
export const PAYMENT_METHOD = loadUrl(BACKEND, `payment`);
export const CHECKOUT_PAYMENT_METHOD = loadUrl(BACKEND, `checkout/payment-methods`);
export const CHECKOUT_SHIPPING_OPTION = loadUrl(BACKEND, `checkout/shipping-methods`);
export const CHECKOUT_SHIPPING_ADDRESS = loadUrl(BACKEND, `checkout/shipping-addresses`);
export const CHECKOUT_DISCOUNT_SET = loadUrl(BACKEND, `checkout/discounts`);
export const CHECKOUT_DISCOUNT_DELETE = (code: string) => loadUrl(BACKEND, `checkout/discounts/${code}`);
export const CHECKOUT_DOCUMENTS = loadUrl(BACKEND, `checkout/documents`);
export const CHECKOUT_DOCUMENTS_FILE = (file: string) => loadUrl(BACKEND, `checkout/documents/${file}`);


export const CATEGORY = (alias: string) => loadUrl(BACKEND, `category/${alias.toLowerCase()}`);
export const CATEGORIES = loadUrl(BACKEND, `category`);
export const FILTER_CATEGORY = (params: {}) =>
    loadUrl(
        BACKEND,
        `category/search/filter?${qs.stringify(params, {
            arrayFormat: 'brackets',
        })}`
    );

export const TERMS = loadUrl(BACKEND, `terms`);
export const TERM = (alias: string) => loadUrl(BACKEND, `terms/${alias.toLowerCase()}`);

export const GET_SETTINGS = loadUrl(BACKEND, `settings`);
export const GET_MENU_BY_ID = (id: string) => loadUrl(BACKEND, `static/menu/${id}`);

export const TRANSLATIONS = loadUrl(BACKEND, `static/translations/frontend`);

export const MYTICKETS = loadUrl(BACKEND, `my/tickets`);
export const NEWTICKET = (code: string) =>
    loadUrl(BACKEND, `my/tickets/${code.toUpperCase()}/messages`);

export const FILTER_MESSAGES = (code: string, params: {}) =>
    loadUrl(
        BACKEND,
        `my/tickets/${code.toUpperCase()}/messages?${qs.stringify(params, {
            arrayFormat: 'brackets',
        })}`
    );
export const MESSAGE_READ = (code: string) =>
    loadUrl(BACKEND, `my/messages/${code.toUpperCase()}/make-read`);

export const SERVICE_CUSTOMERS = loadUrl(BACKEND, `service/customers`);

export const MESSAGES = loadUrl(BACKEND, `messages`);
export const MY_MESSAGES_DISCOVER = loadUrl(BACKEND, `my/messages/discover`);

export const MY_MESSAGES = (params: {}) => loadUrl(BACKEND, `my/messages?${qs.stringify(params, {arrayFormat: 'brackets'})}`);
