import React, { useEffect} from 'react';
import {toastr} from 'react-redux-toastr';
import {useAppDispatch, useAppSelector} from "../../hook";
import {useTranslation} from "react-i18next";
import {setComment, setInternalOrderNumber} from "../../reducers/cart";
import Box from "@mui/material/Box";
import {BoxProps, styled, useMediaQuery} from "@mui/material";
import CheckoutDropzone from "./CheckoutDropzone";
import {StyledBox} from "../../components/common/StyledComponents";
import {AsteriskTypography} from "../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import theme from "../../theme";


const StyledBoxContainer = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down("md")]:{
        width: '100%'
    }
}))


interface ContainerBoxProps extends BoxProps {
    mandatory: boolean | undefined
}

const ContainerBox = styled(({ mandatory, ...otherProps }: ContainerBoxProps) => (
    <Box {...otherProps}/>
))(({theme, mandatory}) => ({
    width: '100%',
}))

export default function AdditionalInformation() {

    const comment = useAppSelector(state => state.cart.comment)
    const internalOrderNumber = useAppSelector(state => state.cart.internalOrderNumber)
    const settings = useAppSelector(state => state.staticContent.settings)
    const {t} = useTranslation(['checkoutPage', 'messages',])
    const shopUsesPrices = !!(settings && settings.find(e => e.name === "shop_uses_prices" && e.value === "1"))
    const isInternalOrderNumberMandatory = !!(settings && settings.find(e => e.name === 'is_internal_order_number_mandatory' && e.value === '1'))
    const showOrderDocuments = !!(settings && settings.find(e => e.name === "show_order_documents" && e.value === "1"))

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const dispatch = useAppDispatch()
    useEffect(() => {
        if (comment) {
            dispatch(setComment(comment))
        }
        if (internalOrderNumber) {
            dispatch(setInternalOrderNumber(internalOrderNumber))
        }
    }, [])


    const setCommentFunction = (comment: string) => {
        dispatch(setComment(comment))
        localStorage.setItem('comment', comment)
    };

    const setInternalOrderNumberFunction = (value: string) => {
        dispatch(setInternalOrderNumber(value))
        localStorage.setItem('internalOrderNumber', value)
    };

    const handleChangeInternalNumberFunction = (e:  React.ChangeEvent<HTMLInputElement>) => {
            e.target.value.length <= 30
                ? setInternalOrderNumberFunction(e.target.value)
                : toastr.info('', t('messages:30 restriction'))
    }
    const handleChangeCommentFunction = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.value.length < 256
            ? setCommentFunction(e.target.value)
            : toastr.info('', t('messages:255 restriction'))

    }

    return (
        <ContainerBox mandatory={isInternalOrderNumberMandatory} >
            {!mobileView && <AsteriskTypography mandatory={isInternalOrderNumberMandatory}> * </AsteriskTypography>}
            <Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.breakpoints.down("md")]:{
                        alignItems: 'center'
                    }
                }}>
             <StyledBoxContainer>
                    <StyledBox
                        label={ 
                            (isInternalOrderNumberMandatory ? '*' : '') + 
                            t('checkoutPage:internalOrderNumber')
                        }
                        name={'number'}
                        value={ internalOrderNumber || '' }
           
                        handleChange={handleChangeInternalNumberFunction}
                        />
            </StyledBoxContainer>
            
            <StyledBoxContainer>
                      <StyledBox
                        label={t("comment")}
                        name='comment'
                        multiline={true}
                        maxRows={'255'}
                        value={comment}
                        handleChange={handleChangeCommentFunction}
                            />
            </StyledBoxContainer>

                </Box>
                {shopUsesPrices && showOrderDocuments &&
                <CheckoutDropzone
                    showFilesInPreview={false}
                    messageSent={true}
                />
                }
            </Box>
        </ContainerBox>
    );
}
