import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { primaryColor } from "../../../../theme";
import { ProductDetailsPageContent } from "../../../../interfaces/backend";
import { useAppDispatch, useAppSelector } from "../../../../hook";
import { Link } from "react-router-dom";
import { addCartItem } from "../../../../reducers/cart";
import { formatMoney } from "../../../../utils/localization";
import { Availability } from "../../../common/general/availability";
import AddToCartButton from "../../../common/AddToCartButton";
import ProductDetailsOverview from "../../../common/general/productDetailsOverview";

const MobileMenuContent = () => {
  const { t } = useTranslation("productPage");

  const dispatch = useAppDispatch();
  const productDetails: ProductDetailsPageContent = useAppSelector(
    (state) => state.productDetails.productDetails
  );
  const handleAddItem = () => {
    dispatch(
      addCartItem({
        code: productDetails.code,
      })
    );
  };

  return (
    <Box
      sx={{
        width: "320px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginTop: "15px",
        color: primaryColor.machineViewerPopupTextColor,
        fontSize: "1.6rem",
      }}
    >
      <Box
        sx={{
          height: "60px",
          width: "100%",
          padding: "15px",
          borderRadius: "1.9px",
        }}
      >
        <Typography sx={{ cursor: "pointer", textAlign: "center" }}>
          {t("openGroup")}
        </Typography>
      </Box>

      <Box
        sx={{
          height: "fit-content",
          textAlign: "center",
          width: "inherit",

          "& > *": {
            margin: "12px 0",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "column",
            margin: "15px",
            fontWeight: 400,
            height: "50px",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {productDetails.name}
          </Typography>

          <Typography>{productDetails.code}</Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "center",
            alignItems: "center",
            height: "50px",
            margin: "-5px 0",
          }}
        >
          <Availability
            inStock={productDetails.inStock}
            isOrderable={productDetails.isOrderable}
            iconSx={{ height: "19px" }}
          />

          <Typography>
              {productDetails.price ? formatMoney(productDetails.price) : t('priceOnInquiry')}
          </Typography>
        </Box>

        <Box
          sx={{
            // backgroundColor: primaryColor.badgeColor,
            // cursor: productDetails.isOrderable ? "pointer" : undefined,
            // width: "100%",
          }}
        >
          <AddToCartButton
            buttonContent={t("addItem") as string}
            buttonStyles={{width: "100%"}}
            onClick={handleAddItem}
            isDisabled={!productDetails.isOrderable}
          />
        </Box>

        <ProductDetailsOverview linkTo={productDetails.alias} iconSx={{height: '19px', marginRight: '15px'}} iconText={t("goToProductDetails") as string} containerSx={{height: "50px", justifyContent: "center"}}/>  

      </Box>
    </Box>
  );
};

export default MobileMenuContent;
