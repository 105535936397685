import {Box, MenuItem, Select, Typography} from "@mui/material";
import {defaultFonts, primaryColor, highlightedFonts} from "../../theme";
import {Dispatch, SetStateAction} from "react";
import {useTranslation} from "react-i18next";

interface SortingParams {
  sortValue: string,
  setSortValue: Dispatch<SetStateAction<string>>
}


const Sorting = (params: SortingParams) => {

    const {sortValue, setSortValue} = params
    const sortingSelectStyles =
    {   
            // height: 20,
            '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
                border: 'none',
                display: 'flex',
                alignItems: 'baseline',
                justifyContent: 'end',
                padding: 0,
                backgroundColor: 'transparent',

            },
            '& .MuiSelect-icon': {
                color: primaryColor.whiteText,
                top: 'calc(50% - .3em)'
            },
        }

    const menuItemStyle =
        {
            '&.MuiButtonBase-root.MuiMenuItem-root': {
                paddingY: 'unset',
                background: primaryColor.drawerList,
                height: '58px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px',
            },
        }
    const menuItem = (params: {value: string, name: string, key: number } ) => {
      const {value, name, key} = params
        return (
            <MenuItem
                key={key}
                sx={menuItemStyle}
                value={name}
            >
                <Typography
                // variant={'h6'}
                    sx={{
                        '&:hover': {
                            color: primaryColor.whiteText
                        },
                        fontWeight: {xs: 400, md: 300},
                        fontSize: highlightedFonts,
                        color: primaryColor.drawerText,
                        marginLeft: '5px',
                        marginRight: '25px'
                    }}
                >
                    {value}
                </Typography>
            </MenuItem>
        )

    }
    const {t} = useTranslation('searchPage')
    const sortingValues = [
      {value: t('sortAZ'), name: 'sortAZ'},
      {value: t('sortZA'), name: 'sortZA'}
    ]
    //const [sortValue, setSortValue] = useState<DefaultTFuncReturn>(t('sortAZ'))
    return (
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={sortValue ? sortValue : 'sortAZ'}
            onChange={(e) => {
                setSortValue(e.target.value)
            }}
            sx={
                sortingSelectStyles
            }
            MenuProps={{
                PaperProps: {
                    sx: {
                        '& .MuiList-root.MuiMenu-list': {
                            paddingY: '0'
                        },
                        borderRadius: 'unset',
                        padding: 'unset',
                        maxWidth: 'unset',
                        // left: 'unset !important' ,
                        marginTop: '7px',
                    }
                },
            }}
            IconComponent={
                (props) =>
                    <Box
                        component={'img'}
                        src={'/images/arrowUp.svg'}
                        {...props} />
            }
        >
          {
            sortingValues.map((sort, key) => menuItem({...sort, key}))
          }
        </Select>
    )
}

export default Sorting
