import {call, put, takeEvery} from 'redux-saga/effects';

import {
  FORGOTPASSWORD,
  LOGIN,
  ME,
  MY_ROLE,
  REFRESHTOKEN,
  REGISTER,
  RESETPASSWORD,
  SERVICE_CUSTOMERS
} from '../constants/api';
import {openErrorSnack, openSimpleErrorSnack, receiveForgotPass, receiveRegisterData} from '../reducers/start';

import {callHttp} from '../utils/api';
import {get, post} from '../utils/httpUtil';

import {forgotPassAction, loginAction, registerUserAction, resetPasswordAction} from "../interfaces/actions";
import {
  logout,
  receiveAuthData,
  setMe,
  setSelectUserModalOpen,
  setServiceCustomers,
  setTemporaryAuthData,
  successResetPassword
} from "../reducers/auth";
import i18next from "i18next";
import {Customer, LoginResponse, Me, UserRoleTypes} from "../interfaces/backend";
import {Cookies} from "react-cookie-consent";

i18next.loadNamespaces('messages');

export function* registerUser(action: registerUserAction) {
  const {data} = action.payload
  try {
    yield callHttp(post, REGISTER, data)
    yield put(receiveRegisterData())
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* login(action: loginAction) {
  const {email, password} = action.payload
  try {
    const data: LoginResponse = yield callHttp(post, LOGIN, {email, password})
    if (data) {

      yield put(
        setTemporaryAuthData({
          data: data,
          storedUrls: [MY_ROLE, SERVICE_CUSTOMERS]
        })
      )
      const role: { code: string } = yield callHttp(get, MY_ROLE)

      if (role?.code) {
        if (role.code === UserRoleTypes.ServiceUser) {
          yield put(setSelectUserModalOpen(true));
        } else {
          yield put(receiveAuthData({
            token: data.token,
            refreshToken: data.refreshToken,
            expire: data.expire
          }));

        }
      }
    }
  } catch (err) {
    yield put(openSimpleErrorSnack(i18next.t('messages:Wrong creds')))
  }
}

export function* forgotPassword(action: forgotPassAction) {
  const {email} = action.payload
  try {
    yield callHttp(post, FORGOTPASSWORD, {email})
    yield put(receiveForgotPass())
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* refreshToken() {
  try {
    Cookies.remove('token')
    const refreshToken = Cookies.get('refreshToken');
    // let remember = true
    // const tokenExpired = localStorage.getItem('sessionExpired');
    // if (tokenExpired) remember = false;

    const data: LoginResponse = yield call(post, REFRESHTOKEN, {
      refreshToken: refreshToken,
    });

    yield put(
      receiveAuthData({
        token: data.token,
        refreshToken: data.refreshToken,
        // remember,
        expire: data.expire
      })
    );
  } catch (err) {
    yield put(logout());
  }
}

export function* getMe() {
  try {
    const user: Me = yield callHttp(get, ME)
    yield put(setMe(user))
  } catch (err: any) {
    yield put(openErrorSnack(err.message))
  }
}

export function* resetPassword(action: resetPasswordAction) {
  try {
    const {email, password, token} = action.payload
    yield callHttp(post, RESETPASSWORD, {
      email: email,
      password: password,
      token: token,
    });
    yield put(successResetPassword());
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export function* getServiceCustomers() {
  try {
    const customers: Array<Customer> = yield callHttp(get, SERVICE_CUSTOMERS);
    yield put(setServiceCustomers(customers));
  } catch (err: any) {
    yield put(openErrorSnack(err.message));
  }
}

export default function* authSaga() {
  yield takeEvery('auth/registerUser', registerUser)
  yield takeEvery('auth/login', login)
  yield takeEvery('auth/forgotPassword', forgotPassword)
  yield takeEvery('auth/getMe', getMe)
  yield takeEvery('auth/resetPassword', resetPassword)
  yield takeEvery('auth/getServiceCustomers', getServiceCustomers)
}