import Page from "../page";
import React, { useEffect } from "react";
import DesktopHome from "./DesktopHome";
import {
  Box,
  Typography,
  useMediaQuery,
} from "@mui/material";
import theme, { primaryColor } from "../../theme";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../hook";
import { getMenuHomePage } from "../../reducers/static";
import { getMe } from "../../reducers/auth";
import MobileHome from "./MobileHome";
import { getMyMachines } from "../../reducers/machine";
import MachineCarousel from "../../components/common/carousel/machines";
import { Machine } from "../../interfaces/backend";
import NavigateItem from "./NavigateItem";

const HomePage = () => {
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const smView = useMediaQuery(theme.breakpoints.down("sm"));

  const lgView = useMediaQuery(theme.breakpoints.down("lg"));
  const { t } = useTranslation("homePage");
  const me = useAppSelector((state) => state.auth.me);
  const machines: Array<Machine> = useAppSelector(
    (state) => state.machine.myMachines
  );
  const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getMenuHomePage());
    dispatch(getMe());
    dispatch(getMyMachines());
  }, [dispatch]);

  useEffect(() => {
    const scrollToModelViewer = () => {
      const bottomHeader = document.getElementById("bottomHeader");
      const serviceHeader = document.getElementById("serviceHeader");
      
      if (!serviceUserEmail && bottomHeader) {
        bottomHeader.scrollIntoView({ block: "start", inline: "nearest" });
      } else if (serviceHeader) {
        serviceHeader.scrollIntoView({ block: "end", inline: "nearest" });
      }

    };
    scrollToModelViewer();
  });

  const shouldShowCurved: boolean = !lgView && machines?.length >= 4;
  const navigationItems = [
    {
      condition: machines?.length >= 4,
      content: t("showAllMachines"),
      navigateTo: "/machines",
    },
    {
      condition: machines?.length > 0,
      content: t("orderNewMachine"),
      navigateTo: "/quick-order",
    },
  ];

  return (
    <Page matchToHeaderWidth={true}>
        <Typography
          variant={"h3"}
          sx={{
            color: primaryColor.homeWelcomeUserName,
            fontWeight: 700,
            lineHeight: "normal",
            fontSize: {
              lg: "7rem",
              md: "2.2rem",
              xs: "2.6rem",
            },
            textAlign: "center",
            mt: "40px",
            mb: "40px",
          }}
        >
          {t("welcomeToHome{{firstname}}{{lastname}}", {
            firstname: me?.firstName || "",
            lastname: me?.lastName || "",
          })}
        </Typography>

        {machines?.length > 0 && (
          <Box
            sx={{
              mt: { xs: "40px" },
            }}
          >
            <Typography
              sx={{
                color: primaryColor.homeMyMachineTitle,
                textAlign: "center",
                fontSize: { xs: "1.8rem", md: "3rem" },
                fontWeight: 300,
                textTransform: "uppercase",
                mb: {
                  xs: "40px",
                  lg: "0px"  
                },
                mt: {
                  xs: "20px",
                  lg: "65px"  
                },
              }}
            >
              {t("myMachineTitle")}
            </Typography>
            {!mobileView && (
              <Typography
                sx={{
                  margin: "15px auto 45px",
                  width: { xs: "auto", md: "420px" },
                  maxHeight: "100px",
                  textAlign: "center",
                  color: primaryColor.homeGreetingText,
                  fontSize: { xs: "1rem", md: "2rem" },
                }}
              >
                {t("myMachineGreeting")}
              </Typography>
            )}
          <MachineCarousel machines={machines} curved={shouldShowCurved} />
          </Box>
        )}
            <Box
            sx={{
                mt: {xs: "0px", lg: "40px"},
                paddingY: {xs: "20px", md: '0px'},
                textAlign: "center",
                maxWidth: "288px", 
                margin: "auto",
            }}
            >
            {navigationItems.map((item, index) => (
                item.condition && (
                <NavigateItem
                    key={index}
                    content={item.content}
                    navigateTo={item.navigateTo}
                    variant={lgView ? "button" : "text"}
                />
                )
            ))}
            </Box>

        {smView ? <MobileHome /> : <DesktopHome />}

    </Page>
  );
};

export default HomePage;
