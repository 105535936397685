import {useAppDispatch, useAppSelector} from "../../hook";
import Page from "../page";
import {Box, Container, Typography, useMediaQuery } from "@mui/material";
import {Article} from "../../interfaces/backend";
import {getArticles} from "../../reducers/start";
import {useEffect, useState} from "react";
import  theme, {primaryColor} from "../../theme";
import {useTranslation} from "react-i18next";
import {BreadCrumbsObject} from "../../interfaces/general";
import CustomPagination from "../ListingPage/Pagination";

import DesktopBlog from "./DesktopBlog";
import MobileBlog from "./MobileBlog";

const BlogPage = () => {
    const {t} = useTranslation('common');
    const dispatch = useAppDispatch()
    const makeBread = (): BreadCrumbsObject[] => [
        {name: t('home'), link: '/'},
        {name: t("blogPageTitle"), link: '',},
    ];

    const perPage = 5
    const articles: Array<Article> = useAppSelector(state => state.start.articles);

    const totalPages = Math.ceil(articles.length / perPage);

    const [currentPage, setCurrentPage] = useState(1);
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;

    const articleItems: Article[] = articles
        .slice(startIndex, endIndex)
  const lgView = useMediaQuery(theme.breakpoints.down("lg"));

    useEffect(() => {
        const start = (currentPage - 1) * perPage;
        const range = start + perPage;

        dispatch(getArticles(range));
    }, [currentPage, dispatch]);

 

    return (
        <Page breadCrumbs={makeBread()} matchToHeaderWidth={true}>
                <Box
                    sx={{
                        paddingY: "25px",
                        textAlign: 'center',
                        margin: 'auto',
                        width: '100%',
                        maxWidth: {
                        xs: "480px",
                        sm: "580px",
                        md: "780px",
                        lg: "unset"
                        },

                    }}
                    >
    
                 <Typography
                            sx={{
                                fontWeight: 700,
                                color: primaryColor.blogPageTextColor,
                                fontSize: {xs: "2.6rem", lg: "7rem"},
                            }}
                        >
                    {t("blogPageTitle")}
                    </Typography>
                { articleItems.length > 0 && 
                    (lgView 
                    ? <MobileBlog items={articleItems}/> 
                      : <DesktopBlog items={articleItems}/>
                    )
                }
                </Box>
                <Box
                    sx={{
                        color: primaryColor.drawerText,
                        margin: {
                            xs: "55px 0",
                            lg: "0 0 55px"
                        },

                    }}
                >
                    {articles.length > perPage ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                            
                            }}
                        >
                            <CustomPagination
                                currentPage={currentPage}
                                pagination={Array.from(
                                    {length: totalPages},
                                    (_, i) => i + 1
                                )}
                                setSelectedPage={setCurrentPage}
                            />
                        </Box>
                    ) : null}
                </Box>
        </Page>
    );
}

export default (BlogPage)