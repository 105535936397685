import React from "react";
import { Box, TableRow, Typography } from "@mui/material";
import { formatMoney } from "../../../utils/localization";
import { QuickOrderProduct } from "../../../interfaces/general";
import { StyledTableCell } from "../../../pages/Cart/styleElements";
import InputWrapper from "../../common/InputWrapper";
import { primaryColor, tableFontSize } from "../../../theme";
import PriceRendered from "../../common/priceRendered";
 

export interface TableRowComponentProps {
  product: QuickOrderProduct;
  index?: number;
  onDelete: (code: string) => void;
  onChangeQuantity: (quantity: number) => void;
  customStyles?: React.CSSProperties;
}

export const DeleteIcon = (props: { onClick: () => void }) => {
  return (
    <Box
      component="img"
      src={"/images/icons/deleteIcon.svg"}
      sx={{
        cursor: "pointer",
        width: {
          xs: "13px",
          sm: "15px",
          md: "18px",
        },
        height: {
          xs: "20px",
          sm: "23px",
          md: "26px",
        },
      }}
      onClick={props.onClick}
    />
  );
};

const DesktopTableRow = (props: TableRowComponentProps) => {
  const { product, index, onDelete, onChangeQuantity } = props;
  const tableRows = ["name", "code", "price", "quantity"];

  const generateDeskTopCellContent = (row: string): React.ReactNode | string => {
    switch (row) {
      case "quantity":
        return (
          <InputWrapper
            onChange={(quantity: number) => {
              onChangeQuantity(quantity);
            }}
            value={product.quantity}
          />
        );
      case "price":
      return <PriceRendered productPrice={product.price} productDiscountPrice={product.discountPrice}/>
      default:
        return product[row as keyof QuickOrderProduct];
    }
  };

  return (
    <TableRow key={product.code}>
      <StyledTableCell>{index}</StyledTableCell>

      {tableRows.map((row, cellIndex) => (
        <StyledTableCell key={cellIndex} sx={{borderBottom: "none"}}>
          {generateDeskTopCellContent(row)}
        </StyledTableCell>
      ))}

      <StyledTableCell sx={{ borderBottom: "none" }}>
        <DeleteIcon onClick={()=>onDelete(product.code)} />
      </StyledTableCell>
    </TableRow>
  );
};

export default DesktopTableRow;
