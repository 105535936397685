import {Box, BoxProps, Button, styled, useMediaQuery} from "@mui/material";
import InvoicingCompanyAddress from "./InvoicingCompanyAddress";
import BillingAddress from "../../components/myData/Addresses/BillingAddress";
import OrderOptions from "./OrderOptions";
import ShippingOptions from "./ShippingOptions";
import ShippingAddressComp from "./ShippingAddressComp";
import PaymentMethods from "./PaymentMethods";
import AdditionalInformation from "./AdditionalInfo";
import Terms from "../../components/term/Terms";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hook";
import {CheckoutSummaryProps} from "./CheckoutSummary";
import CheckoutAccordion from "../../components/checkout/CheckoutAccordion/CheckoutAccordion";
import theme from "../../theme";

const ButtonContainer = styled((props: BoxProps) => (
  <Box {...props}  />
))(({theme}) => ({
  [theme.breakpoints.down("md")]: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export default function CheckoutOptions({handleClick, enableBuyButton, checkoutCart}: CheckoutSummaryProps) {
  const [showInvoicingCompanyAddress, setShowInvoicingCompanyAddress] = useState(true)
  const {t} = useTranslation('checkoutPage')
  const settings = useAppSelector(state => state.staticContent.settings)
  const showOrderOptions = settings && settings.find(
    (x) => x.name === 'show_order_options' && x.value !== null && parseInt(x.value) === 1
  );
  const isOrderOptionProduct = checkoutCart?.orderOption === "product" || !showOrderOptions;

  const [showShippingAddress, setShowShippingAddress] = useState(false)

  const mobileView = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    if (isOrderOptionProduct && checkoutCart?.shippingMethod?.isAddressRequired) setShowShippingAddress(true)
    else setShowShippingAddress(false)
  }, [isOrderOptionProduct, checkoutCart?.shippingMethod?.isAddressRequired])

  const ref = React.useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current && showShippingAddress) {
      ref.current.scrollIntoView();
    }
  }, [showShippingAddress]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mb: '250px',
          width: '60%',
          alignSelf: 'center',
          [theme.breakpoints.down("md")]: {
            mb: '70px',
            width: '100%'
          }
        }}
      >
        {showInvoicingCompanyAddress &&
            <CheckoutAccordion
                title={t('invoicingCompanyHeader')}
                content={<InvoicingCompanyAddress setShowInvoicingCompanyAddress={setShowInvoicingCompanyAddress}/>}
                mandatory={false}
            />
        }

        <CheckoutAccordion
          title={t('billingAddress')}
          content={<BillingAddress/>}
          mandatory={false}
        />

        {showOrderOptions &&
            <CheckoutAccordion
                title={t('orderOptions')}
                content={<OrderOptions/>}
                mandatory={true}
            />
        }

        <Box ref={ref}>
          {isOrderOptionProduct &&
              <CheckoutAccordion
                  title={t('shippingOptions')}
                  content={<ShippingOptions/>}
                  mandatory={true}
              />
          }
        </Box>

        {showShippingAddress &&
            <CheckoutAccordion
                title={t('shippingAddress')}
                content={<ShippingAddressComp/>}
                mandatory={true}
            />
        }

        <CheckoutAccordion
          title={t('payment')}
          content={<PaymentMethods/>}
          mandatory={true}
        />

        <CheckoutAccordion
          title={t('additional')}
          content={<AdditionalInformation/>}
        />

        <Box
          sx={{
            mt: '60px',
            padding: {
              xs: '0px 40px',
              md: 0
            },
          }}
        >
          <Terms category='checkout' type='checkbox'/>
        </Box>

        {!mobileView && <ButtonContainer
            sx={{
              mt: '60px',
            }}>

            <Button
                variant="contained"
                onClick={() => handleClick()}
                disabled={!enableBuyButton}
            >
              {t('buyNow')}
            </Button>

        </ButtonContainer>
        }
      </Box>
    </Box>
  )
}