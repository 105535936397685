import React from 'react';
import {useTranslation} from 'react-i18next';
import {Box, Typography, useMediaQuery} from "@mui/material";
import theme, {primaryColor} from "../../../theme";
import {Cart, CheckoutStageType} from "../../../interfaces/backend";
import VoucherArea from "./VoucherArea";
import CostArea from "./CostArea";
import CartTableDesktop from "../../Cart/Table/CartTableDesktop";
import CheckoutCartTableMobile from '../../Cart/Table/CheckoutCartTableMobile';

export interface CheckoutSummaryProps {
    handleClick: () => void,
    enableBuyButton: boolean,
    checkoutCart: Cart
}

const CheckoutSummary = ({handleClick, enableBuyButton, checkoutCart}: CheckoutSummaryProps) => {
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const {t} = useTranslation('checkoutPage')

    return (
        !mobileView ? <Box
                sx={{
                    mb: '50px',
                    backgroundColor: primaryColor.backgroundOverlay,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        maxWidth: {md: '90%', lg: '60%'},
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        variant={'h20'}
                        sx={{
                            color: primaryColor.shoppingCartTitle,
                            fontWeight: 300,
                            mt: '93px',
                            paddingLeft: '16px'
                        }}
                    >{checkoutCart.itemsQuantity} {t('items')}</Typography>

                    {checkoutCart.itemsQuantity > 0 ?
                        <>
                            <CartTableDesktop stage={CheckoutStageType.Checkout} data={checkoutCart}/>
                            <Box
                                sx={{
                                    mt: '90px', mb: '100px',
                                    color: primaryColor.shoppingCartTitle,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <VoucherArea/>
                                <CostArea
                                    checkoutCart={checkoutCart}
                                    handleClick={handleClick}
                                    enableBuyButton={enableBuyButton}
                                />
                            </Box>
                        </>
                        : null
                    }
                </Box>
            </Box>
            :
            <>
                {checkoutCart.itemsQuantity > 0 ?
                    <CheckoutCartTableMobile data={checkoutCart}/>
                    : null
                }
            </>
    )
}

export default (CheckoutSummary)
