import {Box, FormControl, MenuItem, Select, Typography} from "@mui/material";
import {useTranslation} from "react-i18next"
import {locales} from "../../../utils/localization"
import {IndexString, LocalObject} from "../../../interfaces/general";
import {primaryColor} from "../../../theme";
import {ArrowDropDown} from "@mui/icons-material";
import {useAppSelector} from "../../../hook";

interface SelectLanguageProps {
    onSelectOpen?: () => void;
    openState?: boolean;
    mobileView: boolean;

}


const SelectLanguage = (props: SelectLanguageProps) => {
    const {onSelectOpen, openState, mobileView} = props;

    const handleOpen = onSelectOpen ? onSelectOpen : () => {
    };
    // const isOpen = openState !== undefined ? openState : true;
    const token = useAppSelector(state => state.auth.token)
    const languageSelectStyles = !mobileView ? {
        height: 30,
        padding: 0,
        display: 'inline-flex',
        color: primaryColor.whiteText,
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            padding: 0,
            width: '100%',
            color: primaryColor.whiteText
        },
        '& .MuiSelect-icon': {
            color: primaryColor.whiteText
        }
    } : {
        height: 20,
        color: primaryColor.drawerText,
        '& .MuiSelect-select.MuiInputBase-input.MuiInput-input': {
            border: 'none',
            display: 'flex',
            alignItems: (!token) ? 'center' : 'baseline',
            justifyContent: (!token) && 'end',
            padding: 0,
            width: '100%',
            backgroundColor: 'transparent',
            color: primaryColor.whiteText
        },
        '& .MuiSelect-icon': {
            color: primaryColor.whiteText,
            display: (!token) && 'none'
        },
    }

    const formControlStyle = !mobileView ?
        {
            height: 30,
            border: 'none',
            width: 40,
        }
        :
        {
            height: 20,
            border: 'none',
            width: '100%',
        }

    const menuItemStyle = !mobileView ?
        {
            '&.MuiButtonBase-root.MuiMenuItem-root:hover': {
                backgroundColor: primaryColor.textTopHeader
            },
            '&.MuiButtonBase-root.MuiMenuItem-root': {
                padding: 'unset',
                height: '28px',
                paddingX: '10px'
            },
        }
        :
        {
            '&.MuiButtonBase-root.MuiMenuItem-root:hover': {
                color: primaryColor.whiteText
            },
            '&.MuiButtonBase-root.MuiMenuItem-root': {
                paddingY: 'unset',
                background: primaryColor.drawerList,
                color: primaryColor.languageSelectTextMobile,
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                paddingX: '0'
            },
        }
    // const paperPropsStyle = !mobileView ?
    //     {
    //         borderRadius: 'unset',
    //         maxWidth: 'unset',
    //     }
    //     :
    //     {
    //         '& .MuiList-root.MuiMenu-list':{
    //             paddingY: '0'
    //         },
    //         borderRadius: 'unset',
    //         padding: 'unset',
    //         // top: mobileView ? '266px' : '261px'
    //         width: '150%',
    //         maxWidth: 'unset',
    //         left: 'unset !important',
    //         marginTop:  '11px',
    //     }


    const {i18n} = useTranslation()
    const localesList: LocalObject = {...locales()}
    let langs: IndexString = {}

    Object.keys(localesList).forEach(key => {
        langs[key] = localesList[key].label
    })

    return (
        <FormControl
            fullWidth
            sx={formControlStyle}
        >
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={!openState ? i18n.language : ""}
                open={openState}
                onClose={handleOpen}
                onChange={(e) => {
                    i18n.changeLanguage(e.target.value)
                    localStorage.setItem('i18nextLng', e.target.value)
                    window.location.reload()
                }}
                sx={
                    languageSelectStyles
                }
                MenuProps={{
                    PaperProps: {
                        sx: {
                            '& .MuiList-root.MuiMenu-list': {
                                paddingY: '0'
                            },
                            borderRadius: 'unset',
                            padding: 'unset',
                            // top: mobileView ? '266px' : '261px'
                            width: mobileView ? '100%' : 'auto',
                            maxWidth: 'unset',
                            left: mobileView ? 'unset !important' : 'auto',
                            marginTop: mobileView ? '7px' : 'unset',
                        }
                    },
                }}
                IconComponent={
                    (props) => !mobileView ?
                        <ArrowDropDown {...props} />
                        :
                        <Box
                            component={'img'}
                            src={'/images/arrowUp.svg'}
                            {...props} />
                }
            >
                {Object.keys(langs).map((key) => (
                    <MenuItem
                        sx={menuItemStyle}
                        key={key}
                        value={key}
                    >
                        <Typography
                            variant={!mobileView ? 'subtitle2' : 'h6'}
                            sx={{
                                '&:hover': {
                                    color: !mobileView ? 'unset' : primaryColor.whiteText
                                },
                                color: !mobileView ? 'unset' : primaryColor.drawerText
                            }}
                        >
                            {langs[key]}
                        </Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectLanguage
