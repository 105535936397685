import React, {useEffect, useState} from "react";
import Page from "../page";
import {Box, Grid, useMediaQuery} from "@mui/material";
import theme from "../../theme";
import AllTabs from "../../components/common/Tabs/AllTabs";
import useAllMachineDetailsTabs, {
  findItem,
  machineDetailsPageRelations,
} from "../../components/common/Tabs";
import {BreadCrumbsObject} from "../../interfaces/general";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {getMyMachine, getStandardMachine} from "../../reducers/machine";
import {useAppDispatch, useAppSelector} from "../../hook";
import Tab from "../../components/common/Tabs/Tab";
import qs from "qs";
import {useLocation } from "react-router";
import {Document, Machine, MachineTree, MachineTypes} from "../../interfaces/backend";
import TitlePdp from "../ProductDetails/TitlePdp";
import { StyledPaper } from "../../components/common/StyledComponents";
import {SelectedDocumentProps} from "../../components/machineDetails/documents";
 
export interface MachineItemsProps {
  type: MachineTypes,
}

const MachineDetails = (props: MachineItemsProps) => {
  const {type} = props

  const {t} = useTranslation('machineDetails')
  const params = useParams()
  const dispatch = useAppDispatch();
  const location = useLocation()
  const {state} = location
  const search = qs.parse(location.search.replace('?', ''))
  const [allGroups, setAllGroups] = useState<MachineTree[] >([])
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectedName, setSelectedName] = useState<string>()
  const [groupDocuments, setGroupDocuments] = useState<SelectedDocumentProps[] >([])
  const machineDetailsTabs = useAllMachineDetailsTabs()
  const mobileView = useMediaQuery(theme.breakpoints.down('md'))
  const selectedMachine: Machine = useAppSelector(state => state.machine.machine);
  const sparePartsTreeSelectedNode = useAppSelector(
    (state) => state.sparePart.sparePartsTreeSelectedNode
  );

  function getAllGroups(subProduct:  MachineTree[], finalGroupsParam?: SelectedDocumentProps[]) {
    const finalGroups: SelectedDocumentProps[] = finalGroupsParam || [];

    subProduct.forEach( sp => {
      if(sp.isGroup)
      {
        getGroupDocument(sp, finalGroups)
        getAllGroups(sp.children, finalGroups)
      }
    })

    setGroupDocuments(finalGroups)

  }

  function getChildrenDocs(subProduct:  MachineTree[], finalDocs: Document[]) {
    subProduct.forEach(sp => {

      finalDocs.push(...sp.product.document.filter(d => !finalDocs.find(fd => fd.url === d.url)))
      if(sp.children.length > 0) {
        getChildrenDocs(sp.children, finalDocs)
      }
    })
  }

  function getGroupDocument(g:  MachineTree, finalGroups: SelectedDocumentProps[]) {
          if(finalGroups.find(fg => fg.code === g.product.code))
          {
            //return if the group is already in the list
            return;
          }
          const finalDocs: Document[] = [];
          finalDocs.push(...g.product.document)
          if(g.children.length > 0)
          {
            getChildrenDocs(g.children, finalDocs)
          }
          finalDocs.length > 0   && finalGroups.push({code: g.product.code, name: g.product.name, documents: finalDocs.sort((a, b) => {
              const nameA = a.title.toUpperCase(); // ignore upper and lowercase
              const nameB = b.title.toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              // names must be equal
              return 0;
            })})
  }


  const getPage = (name: string) => {
    const result = machineDetailsPageRelations({docsList : groupDocuments}).find(page => page.name === name)
    return result ? result.page : <></>
  }

   useEffect(() => {
    const tab = search.tab?String(search.tab):!mobileView?'spare-parts':''
    if(tab) {
      setSelectedItem(tab)
      setSelectedName(
        findItem(machineDetailsTabs, tab)?.text
      )
    } else {
      setSelectedItem("")

    }
  }, [search])

  useEffect(() => {
    if (selectedMachine.product.code && selectedMachine.spareParts) {
      if(groupDocuments.length === 0 || !groupDocuments.find(gd => gd.code === selectedMachine.product.code))
      {
        getAllGroups([selectedMachine.spareParts])
      }
    }
  }, [selectedMachine, groupDocuments])


  useEffect(() => {
    const alias = params.alias;
    if(type === MachineTypes.MyMachine){
      dispatch(getMyMachine({alias}));
    }else {
      dispatch(getStandardMachine({alias}));
    }
  }, [params.alias, type]);

  const makeBread = (): Array<BreadCrumbsObject> => {
    let list = [{name: t('common:home'), link: '/'}]
    if(state?.prevPath.pathname)
    {
      const {prevPath} = state
      list.push({name: t('common:'+prevPath.pathname.replace(/\//g, '')), link: prevPath.pathname})
    }
    return list
  };

  return (
      <Page breadCrumbs={makeBread()} matchToHeaderWidth={!mobileView}>
      <TitlePdp title={t('machineDetails')} subTitle={selectedMachine.product.name }/>

        {!mobileView ?
              <Box>
                <Grid container
                      sx={{
                        margin: 'auto',
                        zIndex: 1300,
                        paddingTop: '25px',
                        paddingBottom: '80px'
                      }}
                >
                  <Grid key={1} xs={4} lg={3} item>
                    <AllTabs allTabs={machineDetailsTabs} selectedItem={selectedItem} selectedName={selectedName} setSelectedName={setSelectedName} useParameter={true} />
                  </Grid>
                  <Grid key={2} xs={8} lg={9} item>
                   <StyledPaper>
                      <Box>
                        {getPage(selectedItem)}
                      </Box>
                   </StyledPaper>
                  </Grid>
                </Grid>
              </Box>
            :
            <>
            {!selectedItem ? <AllTabs allTabs={machineDetailsTabs} selectedItem={selectedItem} selectedName={selectedName} setSelectedName={setSelectedName} useParameter={true} isMobile={true}  /> :
                <>
                  <Box mb={"25px"}>

                    { !sparePartsTreeSelectedNode.product.alias && <Tab item={selectedItem} text={selectedName ? selectedName : ""}
                         selected={true} isLast={true} isHeader={true} setSelectedItem={setSelectedItem}/>}
                  </Box>
                  {getPage(selectedItem)}
                </>
            }
            </>
        }
      </Page>
  )
}

export default MachineDetails;
