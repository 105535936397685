import React from 'react';

import {Link} from 'react-router-dom';
import {Box, Divider, Typography} from "@mui/material";
import {primaryColor} from "../../../theme";
import {useTranslation} from "react-i18next";
import getShowDiscount, {calculateCartPriceDetails, TakeFirst2DImage} from "../../../utils/productFunctions";
import {CartItem} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import DiscountInfo from "./DiscountInfo";
import {useAppSelector} from "../../../hook";

export default function CheckoutCartTableItemMobile(props: { item: CartItem, useDivider: boolean }) {
  const {item, useDivider} = props;
  const {t} = useTranslation('checkoutPage')
  const settings = useAppSelector(state => state.staticContent.settings)
  const showDiscount = getShowDiscount(settings)
  //const dispatch = useAppDispatch()
  const {prices} = calculateCartPriceDetails(item, settings);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          paddingX: '30px'
        }}
      >
        <Box
          sx={{
            minWidth: '123px',
            height: '77px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white',
            marginRight: '20px'
          }}
        >
          <Box
            component={'img'}
            src={TakeFirst2DImage(item.product.image)}
            sx={{
              height: '77px'
            }}
          />
        </Box>
        <Link
          to={`/product/${item.product.alias}`}
        >
          <Typography
            sx={{
              fontSize: '1.5rem',
              fontWeight: 400,
              color: primaryColor.shoppingCartTitle,
            }}>
            {item.product.name}
          </Typography>
          <Box
            sx={{
              color: primaryColor.shoppingCartTitle,
              marginTop: '16px',
              fontSize: '1.5rem'
            }}
          >
            {item.product.code}
          </Box>
          <Typography
            sx={{
              color: primaryColor.shoppingCartTitle,
              fontSize: '1.5rem'
            }}>
            {`${t('quantity')}: ${item.quantity}`}
          </Typography>
        </Link>
      </Box>
      <Box
        sx={{
          display: 'flex',
          marginTop: '10px',
          paddingX: '30px'
        }}
      >
        <Box sx={{width: '123px', marginRight: '20px', height: 'fit-content', marginTop: 'auto' 
      }}>
          {item.discounts.length > 0 ?
            <DiscountInfo discounts={item.discounts}/>
            : null}
        </Box>

        {showDiscount && prices.length > 1 ?
          <Box>
            {
              prices.map((price, index) =>
                <Box key={index}>
                  <Typography
                    variant={'h15'}
                    sx={(index < prices.length - 1) ? {
                      textDecoration: 'line-through',
                      textDecorationColor: primaryColor.lineThroughPrice,
                      textDecorationThickness: '1px',
                      textDecorationTrim: 'auto',
                      color: primaryColor.lineThroughPrice,
                      opacity: 0.3,
                    } : {color: primaryColor.discountCheckoutPage}}
                  >
                    {formatMoney(price)}
                  </Typography>
                </Box>
              )
            }
          </Box>
          :
          <Typography variant={'h15'}>
            {formatMoney(prices.pop() as number)}
          </Typography>
        }
      </Box>
      {useDivider && <Divider
          sx={{
            backgroundColor: primaryColor.textHeader,
            marginY: '15px'
          }}
      />}
    </Box>
  )
}