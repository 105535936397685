import React, {ChangeEvent, useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hook";
import {addShippingOption, getShippingOptions} from "../../reducers/cart";
import RadioGroup from "./RadioGroup";


export default function ShippingOptions() {

    const dispatch = useAppDispatch()
    const shippingOptions = useAppSelector(state => state.cart.shippingOptions)

    const shippingMethod = useAppSelector(state => state.cart.checkoutCart.shippingMethod)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            dispatch(addShippingOption({id: event.target.value}))
    }
    useEffect(() => {
        dispatch(getShippingOptions())
    }, [])

    return (
       <RadioGroup
           options ={shippingOptions}
           value= {shippingMethod?.id}
           handleChange = {handleChange}
           isShippingOption = {true}
       />
    )
}
