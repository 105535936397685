import {Box, Tooltip, Typography, useMediaQuery} from "@mui/material"
import theme, {defaultFonts, primaryColor} from "../../../theme";
// import {useTranslation} from "react-i18next";
import {Machine} from "../../../interfaces/backend";
// import HoveredBox from "../HoveredBox";
import {Link} from "react-router-dom";
import React from "react";
import MobileItemMenu from "./mobile/MobileItemMenu";
import {TakeFirst2DImage} from "../../../utils/productFunctions";
import { ListingBoxWrapper, ListingBoxContent, ListingBox, ListingTypography } from "./style";
import {useLocation} from "react-router";

interface MachineProps {
  machine: Machine,
}

const MachineItem = (props: MachineProps) => {
  const {machine } = props
  const machineProduct = machine.product;
  const Image2D = TakeFirst2DImage(machineProduct.image)
  const { pathname } = useLocation();

  return (
      <ListingBoxWrapper>
            <Link to={`/my/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
                <ListingBox imageUrl={Image2D} sx={{minHeight: '215px'}}/>
            </Link>

  <ListingBoxContent>
    <Link to={`/my/machine/${machineProduct.alias}`} state={{prevPath: {pathname: pathname}}}>
        
        <Tooltip title={machineProduct.name} placement="top-start">
          <ListingTypography >{machineProduct.name}</ListingTypography>
        </Tooltip>
        
        <Tooltip title={machineProduct.code} placement="top-start">
          <ListingTypography >{machineProduct.code}</ListingTypography>
        </Tooltip>
    </Link>
    {/*            <HoveredBox
                        title={t('Sensor Data')}
                        icon={'/images/Sensor.svg'}
                        hoveredIcon={'/images/OrangeSensor.svg'}
                        alias={'#'}
                    />*/}

    {/*{!mobileView && machineProduct.document.length ? <HoveredBox*/}
    {/*    title={t('document')}*/}
    {/*    icon={'/images/Document.svg'}*/}
    {/*    hoveredIcon={'/images/OrangeDocument.svg'}*/}
    {/*    alias={machineProduct.alias}*/}
    {/*/> : <></>}*/}
  </ListingBoxContent>
</ListingBoxWrapper>
)
}

export default (MachineItem)