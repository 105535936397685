import React, {ChangeEvent, useState} from "react";
import {
    Box,
    FormControl,
    FormControlLabel,
    FormControlProps,
    Radio,
    RadioGroup,
    styled,
    Tooltip,
    useMediaQuery
} from "@mui/material";
import {OrderOption, PaymentMethod, ShippingOption} from "../../interfaces/backend";
import theme, {primaryColor} from "../../theme";
import {formatMoney} from "../../utils/localization";
import {StyledTypography} from "../../components/common/StyledComponents";


interface RadioGroupProps {
    options: Array<ShippingOption | PaymentMethod | OrderOption>,
    value?: number | string,
    handleChange?: (event:ChangeEvent<HTMLInputElement>) => void,
    isShippingOption?: boolean
}

export const StyledFormControl = styled((props: FormControlProps) => (
    <FormControl {...props}/>
))(({theme}) => ({
    marginTop: "15px",
    [theme.breakpoints.down("md")]:{
        position: 'relative',
        bottom: '10px',
        marginTop: "0px",
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    }
}))

export default function RadioGroupComponent(props: RadioGroupProps) {
    const {options, value, handleChange, isShippingOption} = props

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const [openDesc, setOpenDesc] = useState(false)

    return (
        <StyledFormControl>
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                {options.map((option, index) =>
                    <Box sx={{
                        marginX: 0,

                        // display: 'flex',
                        // alignItems: 'center',
                        // flexDirection: 'column'
                    }}
                         key={index}>
                        <Box  sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            marginTop: mobileView ? '1.5px' :'0px'
                        }}>
                        <FormControlLabel
                            value={"id" in option ? option.id : option.code}
                            control={
                                <Radio
                                    sx={{
                                        color: primaryColor.whiteText,
                                        '&.Mui-checked': {
                                            color: primaryColor.discountCheckoutPage
                                        },
                                        ml: mobileView ? '50px' : '15px'
                                    }}
                                />
                            }
                            label={option.name}
                            sx={{
                                '&.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                                    fontSize: mobileView ? '1.5rem' : '2rem',
                                    fontWeight: 400,
                                    color: primaryColor.whiteText
                                }
                            }}
                        />
                        {isShippingOption && "price" in option && option.price > 0 &&
                            <StyledTypography sx={{mr: '15px'}}>{formatMoney(option.price)}</StyledTypography>
                        }
                        {isShippingOption && "description" in option && option.description !== "" &&
                            <Tooltip
                                title={
                                    <Box
                                        dangerouslySetInnerHTML={{__html: option.description}}
                                    />
                                }
                                placement="top-start"
                            >
                                <Box
                                    component={'img'}
                                    src={'/images/icons/infoIconCheckout.svg'}
                                    onClick={() => setOpenDesc(!openDesc)}
                                />
                            </Tooltip>
                        }
                        </Box>
                        {mobileView && openDesc && isShippingOption && "description" in option && option.description !== "" &&
                        <Box
                            dangerouslySetInnerHTML={{__html: option.description}}
                            sx={{
                                marginLeft: '80px',
                                color: primaryColor.filterText,
                                // position: 'relative',
                                marginTop: '-20px'
                            }}
                        />
                        }

                    </Box>
                )}
            </RadioGroup>
        </StyledFormControl>
    )
}
