import * as React from 'react';
import {ReactNode, useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import theme, {primaryColor} from "../../../theme";
import {ListItem, SxProps, useMediaQuery} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {setOpenedDrawerID} from "../../../reducers/static";
import {useNavigate} from "react-router-dom";
import {useLocation } from "react-router";
import { setMyDataOpened } from '../../../reducers/styleControls';

interface drawerProps {
    additionalButtonStyle?: SxProps,
    icon?: (open: boolean) => ReactNode,
    list?: Array<JSX.Element>,
    element?: JSX.Element,
    id: string,
}

export default function CustomDrawer(props: drawerProps) {
    const {additionalButtonStyle, list, icon, element, id} = props
    const [open, setOpen] = React.useState(false)
    const navigate = useNavigate();
    const location = useLocation()

    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);

    const openedDrawerID = useAppSelector(state => state.staticContent.openedDrawerID)
    const dispatch = useAppDispatch()
    const mobileView = useMediaQuery(theme.breakpoints.down('md'))
    const styledDrawer  = {
            '& .MuiPaper-root.MuiDrawer-paper': {
                top: serviceUserEmail ? '135px' : '60px',
                backgroundColor: primaryColor.background
            },
        }
 

    const handleClick = (currentPath: string) => {
      if (currentPath === '/my-account/my-data') {
        dispatch(setMyDataOpened(false));
      } else {
        navigate('/my-account/my-data')
      }
      dispatch(setOpenedDrawerID(''));
      setOpen(!open);
    };     


    useEffect(() => {
        if (openedDrawerID !== id) {
            setOpen(false);
        } else {
          setOpen(true);
        }
    }, [openedDrawerID, id]);

  const DrawerList = () => {
    if (element && mobileView) {
      return (element)
    } else if (list) {
      return (<List>
        {list.map((item, index) =>
            <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  if (!mobileView) {
                     handleClick(location.pathname)
                    } 
                
                }}
                sx={{
                  borderTop: '4.5px solid' + primaryColor.background,
                  width: '550px',
                }}
            >
              {item}
            </ListItem>
        )}
      </List>)
    } else
      return <></>
  }



    return (
        <Box>
            <Button
                sx={{
                    padding: 'unset',
                    minWidth: 'unset',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: 'unset',
                    '&:hover': {
                        backgroundColor: 'transparent'
                    },
                    ...additionalButtonStyle,
                }}
                onClick={() => {
                    if (mobileView) {
                      if(id === 'account-menu')
                        navigate('/my-account')
                      else {
                        setOpen(!open);
                        dispatch(setOpenedDrawerID(open ? "" : id))
                      }
                    } else {
                      handleClick(location.pathname)
                    }

                }}
            >
                {icon ? icon(open) :
                    <img
                        src={!open ? '/images/drawerIcon.svg' : '/images/arrowDown.svg'}
                        height={!open ? '18px' : '11px'}
                        width={'25px'}
                        alt={"drawer icon"}

                    />}
            </Button>
          {mobileView ?
            <Drawer
                anchor={mobileView ? 'top' : 'right'}
                open={open}
                onClose={() => setOpen(false)}
                sx={styledDrawer}
            >
                <Box
                    role="presentation"
                    sx={{marginBottom: '70px'}}
                >
                {
                  DrawerList()
                }
              </Box>
            </Drawer>
            : <Box sx={{
            display: open ? 'flex' : 'none',
            position: "absolute",
            alignItems: "flex-end",
            left: '-350px',
            top: '60px',
            width: '550px',
            height: '110px',
        }}>
            <DrawerList/>

        </Box>

        }
        </Box>

    );
}
