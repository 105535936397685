import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import {Link} from 'react-router-dom';
import {Box, Typography} from "@mui/material";
import { defaultFonts, primaryColor } from '../../../theme';
import {useTranslation} from "react-i18next";
import getShowDiscount, {calculateCartPriceDetails, TakeFirst2DImage} from "../../../utils/productFunctions";
import {useAppDispatch, useAppSelector} from "../../../hook";
import {CartItem, CheckoutStageType} from "../../../interfaces/backend";
import {formatMoney} from "../../../utils/localization";
import InputWrapper from "../../../components/common/InputWrapper";
import {deleteCartItem, updateCartItem} from "../../../reducers/cart";
import {StyledTableCell} from "../styleElements";
import DiscountInfo from "./DiscountInfo";

export default function CartTableItemDesktop(props: { item: CartItem, position: number, stage: CheckoutStageType }) {
  const {item, position, stage} = props;
  const {t} = useTranslation('checkoutPage')
  const settings = useAppSelector(state => state.staticContent.settings)
  const showDiscount = getShowDiscount(settings)
  const dispatch = useAppDispatch()
  const {prices, lastPrice} = calculateCartPriceDetails(item, settings);

  const onChangeQuantity = (code: string, quantity: number) => {
    dispatch(updateCartItem({
      code: code,
      quantity: quantity,
    }))
  }

  return (
    <TableRow>
      <StyledTableCell
      sx={{textAlign: 'center'}}
      > {position} </StyledTableCell>
      <StyledTableCell>
        <Box
          sx={{
            width: {xs: '70px', lg: '99px'},
            height: '62px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'white'
          }}
        >
          <Box
            component={'img'}
            src={TakeFirst2DImage(item.product.image)}
            sx={{
              height: '62px',
            }}
          >
          </Box>
        </Box>
      </StyledTableCell>
      <StyledTableCell sx={{
        width: '100%',
        minWidth: {md: '230px', lg: '300px'},
        maxWidth: {md: '300px',lg: '450px'}
        }}
        >
        <Link
          to={`/product/${item.product.alias}`}
        >
          <Typography
            sx={{
              fontSize: defaultFonts,
              fontWeight: 400,
              color: primaryColor.shoppingCartTitle,
            }}
          >
            {item.product.name}
          </Typography>
          <Typography
            variant={'h15'}
            sx={{
              color: primaryColor.shoppingCartTitle,
            }}
          >
            {`${t('materialNumber')}: ${item.product.code}`}
          </Typography>
        </Link>
        {stage === CheckoutStageType.Checkout && item.discounts.length > 0 ?
          <DiscountInfo discounts={item.discounts}/>
          : null}
      </StyledTableCell>

      <StyledTableCell sx={{textAlign: 'right', fontWeight: 300}}>
        {showDiscount && prices.length > 1 ?
          <>
            {
              prices.map((price, index) =>
                <Box key={`${price}_${index}`}> 
                  <Typography
                    variant={'h20'}
                    sx={{
                      fontSize: defaultFonts, 
                      ...(index < prices.length - 1
                        ? {
                            textDecoration: 'line-through',
                            textDecorationColor: primaryColor.lineThroughPrice,
                            textDecorationThickness: '1px',
                            textDecorationTrim: 'auto',
                            color: primaryColor.lineThroughPrice,
                            opacity: 0.3,
                          }
                        : { color: primaryColor.discountCheckoutPage }),
                    }}
                  >
                    {formatMoney(price)}
                  </Typography>
                </Box>
              )
            }
          </>
          :
          <Typography variant={'h20'}>
            {formatMoney(prices.pop() as number)}
          </Typography>
        }
      </StyledTableCell>

      <StyledTableCell>
        {stage === CheckoutStageType.ShoppingCart ?
          <InputWrapper
            onChange={(val: number) => onChangeQuantity(item.code, val)}
            value={item.quantity}
          />
          :
          <Typography sx={{textAlign: 'center',
          
        }}>{item.quantity}</Typography>
        }
      </StyledTableCell>
      <StyledTableCell sx={{textAlign: 'right'}}>
        {formatMoney(lastPrice * item.quantity)}
      </StyledTableCell>
      {stage === CheckoutStageType.ShoppingCart ?
        <TableCell sx={{
          borderBottom: 'none',
          width: '55px'
        }}>
          <Box
            component={'img'}
            alt='cart'
            onClick={() => dispatch(deleteCartItem({code: item.code}))}
            src='/images/icons/delete-icon-new.svg'
            sx={{
              cursor: 'pointer'
            }}
          />
        </TableCell>
        : null
      }
    </TableRow>
  )
}