import * as React from 'react';
import {ReactNode, useState} from 'react';
import {Box, styled, TypographyProps, useMediaQuery} from "@mui/material";
import Typography from "@mui/material/Typography";
import theme, {primaryColor} from "../../../theme";
import CheckoutArrow from "../../common/svg/checkoutArrow";

interface AsteriskTypographyProps extends TypographyProps {
    mandatory: boolean | undefined,
    isMobileView?: boolean
}

export const AsteriskTypography = styled(({ 
    mandatory, isMobileView, ...otherProps }: AsteriskTypographyProps) => (

    <Typography {...otherProps} variant={'h20'}/>
))(({theme, mandatory, isMobileView, }) => ({
    color: primaryColor.asteriskColor,
    marginRight: '4px',
    opacity: mandatory ? 1 : 0,
    marginBottom: '19px',
    [theme.breakpoints.down("md")]:{
        marginBottom:  '40px' ,
        color: primaryColor.whiteText,
    }
}));

interface CheckoutAccordionProps {
    title: string | ReactNode,
    content: ReactNode,
    mandatory?: boolean,
    ref?:   React.RefObject<HTMLElement>,
}

export default function CheckoutAccordion(props: CheckoutAccordionProps) {
    const {title, content, mandatory} = props

    const [rotate, setRotate] = useState(false)
    const [expanded, setExpanded] = useState<boolean>(true)

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    const handleChange = () => {
        setExpanded(!expanded)
        setRotate(!rotate)
    }

    const menuStyling =
        {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '&:hover': {
                cursor: 'pointer'
            },
            paddingY: mobileView ? '14px' : 'unset'
        }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: '20px',
                alignItems: 'unset',
                [theme.breakpoints.down("md")]: {
                    alignItems: 'center',
                    backgroundColor: primaryColor.pdpBackground
                }
            }}
        >
            <Box
                sx={menuStyling}
                onClick={() => handleChange()}
            >
                <AsteriskTypography mandatory={mandatory} isMobileView ={mobileView}> * </AsteriskTypography>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: mobileView ? 'column' : 'row',
                        alignItems: 'center'
                    }}
                >
                    <Box
                        sx={{
                            fontSize: '2rem',
                            color: primaryColor.shoppingCartTitle,
                            mr: '10px',
                            ml: mandatory ? '0' : '-15px',
                            textTransform: 'uppercase',
                            [theme.breakpoints.down("md")]: {
                                mb: '10px',
                                mr: '0px'
                            }
                        }}
                    >
                        {title}
                    </Box>
                    <CheckoutArrow rotate={rotate}/>
                </Box>
            </Box>
            <Box
                sx={{
                    color: primaryColor.whiteText,
                    overflow: 'hidden',
                    marginBottom: (expanded) ? '30px' : mobileView ? "0px" : '15px',
                    maxHeight: (expanded) ? 'unset' : '0px',
                    transition: 'margin-bottom 300ms, max-height 300ms',
                    width: '100%',
                    padding: {
                      xs: '0px 40px',
                      md: 0
                    },
                }}
            >
                {content}
            </Box>
        </Box>
    )
}