import {Box, BoxProps, styled, Typography, TypographyProps, useMediaQuery} from "@mui/material";
import {StyledBoxButton} from "../../Cart/styleElements";
import {formatMoney} from "../../../utils/localization";
import theme, {primaryColor} from "../../../theme";
import React, {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {CheckoutSummaryProps} from "./index";
import {useAppSelector} from "../../../hook";
import {DiscountValueType} from "../../../interfaces/backend";

export const CustomDivider = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    height: '22px',
    borderColor: primaryColor.shoppingCartTitle,
    borderStyle: 'solid',
    marginTop: '13px',
    minWidth: '420px',
    [theme.breakpoints.down("lg")]: {
        minWidth: '370px',
    }
}))
export const RowFlexBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
}))

export const ColumnFlexBox = styled((props: BoxProps) => (
    <Box {...props}/>
))(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    [theme.breakpoints.down("md")]: {
        alignItems: 'unset'
    }
}))

export const CustomTypography = styled((props: TypographyProps) => (
    <Typography {...props} variant={'h20'}/>
))(({theme}) => ({
    color: primaryColor.shoppingCartTitle,
    marginBottom: '7px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    [theme.breakpoints.down("md")]:{
        overflow: 'unset',
        display: 'block',
        fontSize: '1.5rem',
        marginBottom: '1px'
    }
}))

export default function CostArea ({handleClick, enableBuyButton, checkoutCart}: CheckoutSummaryProps) {
    const {t} = useTranslation('checkoutPage')
    const hasShipping = checkoutCart.shippingMethod?.usePrices
    const settings = useAppSelector(state => state.staticContent.settings)
    const showDiscountPerItem = settings.find(x => x.name === 'show_discount_per_item' && parseInt(x.value) === 1);

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    let totalSave: number = useMemo(() => {
        let temp = checkoutCart.discounts.reduce((accumulator, currentValue) => accumulator + currentValue.discountAmount, 0);
        checkoutCart.items?.forEach(item => {
            temp += item.discountSum * item.quantity;
        })
        return temp
    }, [checkoutCart])

    return(
        <Box sx={{
            [theme.breakpoints.down("lg")]: {
                minWidth: '300px',
            },
            [theme.breakpoints.down("md")]:{
                marginBottom: '50px'
            }
        }}>
            {checkoutCart.totalDiscount > 0 && <RowFlexBox
                sx={{
                    [theme.breakpoints.down("md")]: {
                        justifyContent: 'space-between',
                    }
                }}
            >
                <ColumnFlexBox
                    sx={{
                        marginRight: mobileView ? '0px' : '130px'
                    }}
                >
                    <CustomTypography>{t('productCostWithoutDiscount')}</CustomTypography>
                    {
                        checkoutCart.discounts.map((item, index) =>
                            (!showDiscountPerItem || item.discount.valueType === DiscountValueType.CertainAmount) &&
                            <CustomTypography key={index} sx={{color: primaryColor.discountCheckoutPage}}>{item.discount.discountCode} </CustomTypography>
                        )
                    }
                </ColumnFlexBox>
                <ColumnFlexBox>
                    <CustomTypography>{formatMoney(checkoutCart.totalWithoutDiscount)}</CustomTypography>
                    {
                        checkoutCart.discounts.map((item, index) =>
                            (!showDiscountPerItem || item.discount.valueType === DiscountValueType.CertainAmount) &&
                            <CustomTypography key={index} sx={{color: primaryColor.discountCheckoutPage}}>{formatMoney(-item.discountAmount)}</CustomTypography>
                        )
                    }
                </ColumnFlexBox>
            </RowFlexBox>}

            {checkoutCart.totalDiscount > 0 && !mobileView &&
                <RowFlexBox>
                    <CustomDivider sx={{borderWidth: '0.5px 0 0 0'}}/>
                </RowFlexBox>
            }

            <RowFlexBox
                sx={{
                    [theme.breakpoints.down("md")]: {
                        justifyContent: 'space-between',
                    }
                }}
            >
                <ColumnFlexBox
                    sx={{
                        marginRight: mobileView ? '0px' : '130px'
                    }}
                >
                    <CustomTypography>{t('subtotal')}</CustomTypography>
                    <CustomTypography>{t('taxValue')}</CustomTypography>
                    {hasShipping && (
                        <CustomTypography>{t('Shipping Cost')}</CustomTypography>
                    )}
                </ColumnFlexBox>
                <ColumnFlexBox>
                    <CustomTypography>{formatMoney(checkoutCart.total)}</CustomTypography>
                    <CustomTypography sx={{marginLeft: 'auto'}}>{formatMoney(checkoutCart.totalTax)}</CustomTypography>
                    {hasShipping && (
                        <CustomTypography sx={{marginLeft: 'auto'}}>{formatMoney(checkoutCart.shippingMethod.price)}</CustomTypography>
                    )}
                </ColumnFlexBox>
            </RowFlexBox>

            <RowFlexBox>
                <CustomDivider
                    sx={{
                        borderWidth: '2px 0 0 0',
                        [theme.breakpoints.down("md")]: {
                            width: '100%',
                            minWidth: 'unset'
                        }
                    }}
                />
            </RowFlexBox>

            <RowFlexBox
                sx={{
                    [theme.breakpoints.down("md")]: {
                        justifyContent: 'space-between'
                    }
                }}
            >
                <ColumnFlexBox
                    sx={{
                        marginRight: mobileView ? '0px' : '120px'
                    }}
                >
                    <CustomTypography sx={{fontWeight: mobileView ? 700 : 400}}>{t('totalCost')}</CustomTypography>
                </ColumnFlexBox>
                <ColumnFlexBox>
                    <CustomTypography sx={{fontWeight: 700}}>{formatMoney(checkoutCart.totalWithTaxAndShipping)}</CustomTypography>
                </ColumnFlexBox>
            </RowFlexBox>

            {totalSave > 0 && <RowFlexBox sx={{marginY: '30px'}}>
                <CustomTypography sx={{color: primaryColor.discountCheckoutPage, mb: mobileView ? '0px' : '7px'}}>{t('You save {{money}}', {money: formatMoney(totalSave)})}</CustomTypography>
            </RowFlexBox>}

            <Box
                sx={{
                    mt: '47px',
                    fontSize: '2rem',
                    [theme.breakpoints.down("md")]:{
                        fontSize: '1.5rem',
                    }
                }}
            >
                <StyledBoxButton
                    labelButton={t('buyNow')}
                    disabled = {!enableBuyButton}
                    handleClick = {handleClick}
                />
            </Box>
            <RowFlexBox sx={{
                mt: '13px',
                [theme.breakpoints.down("md")]: {
                    justifyContent: 'space-between',
                }
            }}>
                <Typography
                    sx={{
                        color: primaryColor.discountCheckoutPage,
                        fontSize: '1.5rem'
                    }}
                >{t('pleaseFill')}</Typography>
            </RowFlexBox>
        </Box>
    )
}