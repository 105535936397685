import {createEntityAdapter, createSlice, PayloadAction} from "@reduxjs/toolkit";
import { MenuHomePage, MenuItem, SettingElement, SocialElement, StaticPageContent} from "../interfaces/backend";
import {initialStaticState} from "../interfaces/initialStates";
import {getMenuPayloadAction, getStaticPageContentAction} from "../interfaces/actions";

const staticAdapter = createEntityAdapter();
const initialState: initialStaticState = staticAdapter.getInitialState({
    //stickers: [],
    //menu: {},
    mainMenu: [],
    footerMenu: [],
    headerMenu: [],
    settings: [],
    backgroundUrl: '',
    noImageUrl: '/images/product_example_image.png',
    // shopUsesPrices: true,
    social: [],
    //analyticsActivated: false,
    reCaptchaSiteKey: "",
    openedDrawerID: "",
    staticPageContent: {
        title: '', description: '', image: '', alias: '', category: '', createdAt: new Date()
    },
    menuHomePage: []
});

export const staticSlice = createSlice({
        name: "staticContent",
        initialState: initialState,
        reducers: {
            // setStickers: (state,stickers) => ({
            //   ...state,
            //   stickers,
            // }),
            setFooterMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.footerMenu = action.payload
            },
            setMainMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.mainMenu = action.payload
            },
            setHeaderMenu: (state: initialStaticState, action: PayloadAction<Array<MenuItem>>) => {
                state.headerMenu = action.payload
            },
            setSettings: (state: initialStaticState, action: PayloadAction<Array<SettingElement>>) => {
                state.settings = action.payload
            },
            setBackground: (state: initialStaticState, {payload}) => {
                state.backgroundUrl = payload
            },
            setNoImageUrl: (state: initialStaticState, {payload}) => {
              state.noImageUrl = payload
            },
            // setShopUsesPrices: (state, {payload}) => {
            //     state.shopUsesPrices = payload
            // },
            setSocialLinks: (state: initialStaticState, action: PayloadAction<Array<SocialElement>>) => {
                state.social = action.payload
            },
            // setAnalytics: (state: initialStaticState, action: PayloadAction<boolean>) => {
            //     state.analyticsActivated = action.payload
            // },
            setOpenedDrawerID: (state: initialStaticState, action: PayloadAction<string>) => {
                state.openedDrawerID = action.payload
            },
            // [a.setServiceCustomers]: (state, serviceCustomers) => ({
            //   ...state,
            //   serviceCustomers,
            // }),
            setReCaptchaSiteKey: (state: initialStaticState, {payload}) => {
                state.reCaptchaSiteKey = payload
            },
            setStaticPageContent: (state: initialStaticState, action: PayloadAction<StaticPageContent>) => {
                state.staticPageContent = action.payload
            },
            setMenuHomePage: (state: initialStaticState, action: PayloadAction<Array<MenuHomePage>>) => {
                state.menuHomePage = action.payload
            },
            getStaticPageContent: (state, action: getStaticPageContentAction) => {
            },
            getSocialLinks: () => {
            },
            getSettings: (state: initialStaticState) => {
            },
            getMenu: (state: initialStaticState, action: PayloadAction<getMenuPayloadAction>) => {
            },
            getMenuHomePage: () => {
            }
        }
    }
)

export const {
    setSocialLinks,
    getSocialLinks,
    setSettings,
    setBackground,
    setNoImageUrl,
    // setShopUsesPrices,
    setReCaptchaSiteKey,
    getSettings,
    setFooterMenu,
    setMainMenu,
    setHeaderMenu,
    getMenu,
    // setAnalytics,
    setOpenedDrawerID,
    setStaticPageContent,
    getStaticPageContent,
    getMenuHomePage,
    setMenuHomePage,
} = staticSlice.actions

export default staticSlice.reducer