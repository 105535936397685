import {ThemeProvider} from '@mui/material/styles';
import React, {Suspense, useEffect} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import HomePage from "./pages/home";
import theme from "./theme";
import {CssBaseline} from "@mui/material";
import ListingPage from "./pages/ListingPage";
import QuickOrder from "./pages/QuickOrder";
import LoginPage from "./pages/Login";
import AccountPage from "./pages/Account/AccountPage";
import {useAppDispatch, useAppSelector} from "./hook";
import {getSettings} from "./reducers/static";
import {getCountries, getPublicData, getTitles} from "./reducers/metadata";
import ErrorSnackBar from "./components/common/snackbarError";
import StaticPage from "./pages/StaticPages";
import ProtectedRoute from "./components/common/ProtectedRoute";
import FaviconComp from "./components/common/FaviconComp";
import ProductDetailsPage from "./pages/ProductDetails"
import CartPage from "./pages/Cart";
import SparePartsPage from "./pages/SparePartsPage";
import CheckoutPage from "./pages/Checkout";
import OrderCompletedPage from "./pages/OrderCompleted";
import BlogPage from "./pages/Blog";
import Spinner from "./components/common/Spinner";
import ResetPassword from "./pages/ResetPassword";
import { ListingListTypes, MachineTypes, PageCategory, PasswordActionType } from './interfaces/backend';
import MachineDetails from "./pages/MachineDetails";
import {useAllMyAccountTabs} from "./components/common/Tabs";
import AllTabs from "./components/common/Tabs/AllTabs";
import AccountTabs from "./pages/Account/AccountTabs";

function App() {

    const dispatch = useAppDispatch()
    const token = useAppSelector(state => state.auth.token)
    useEffect(() => {
        dispatch(getPublicData());
        dispatch(getSettings())
        dispatch(getCountries())
        dispatch(getTitles())
    }, [dispatch])

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Suspense fallback={<Spinner/>}>
                <FaviconComp/>
                <Spinner/>
                <ErrorSnackBar/>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path='/'
                            element={<ProtectedRoute><HomePage/></ProtectedRoute>}
                        />
                        <Route
                            path='/login'
                            element={
                                token ? (
                                    <Navigate replace to="/"/>
                                ) : (
                                    <LoginPage/>
                                )}
                        />
                        <Route
                            path='/password/reset'
                            element={<ResetPassword passwordAction={PasswordActionType.Reset}/>}
                        />
                        <Route
                            path='/password/new'
                            element={<ResetPassword passwordAction={PasswordActionType.New}/>}
                        />
                        <Route
                            path='/product/:alias/'
                            element={<ProtectedRoute><ProductDetailsPage/></ProtectedRoute>}
                        />
                        <Route
                            path='/product/:alias/:tab'
                            element={<ProtectedRoute><ProductDetailsPage/></ProtectedRoute>}
                        />
                        <Route
                            path='/my/machines'
                            element={<ProtectedRoute><ListingPage key={1} type={ListingListTypes.MyMachine}/></ProtectedRoute>}
                        />
                        <Route
                            path='/c/:alias/'
                            element={<ProtectedRoute><ListingPage key={2} type={ListingListTypes.Category}/></ProtectedRoute>}
                        />
                        <Route
                            path='/search'
                            element={<ProtectedRoute><ListingPage key={3} type={ListingListTypes.Search}/></ProtectedRoute>}
                        />
                        <Route
                            path='/machines'
                            element={<ProtectedRoute><ListingPage key={4} type={ListingListTypes.StandardMachine}/></ProtectedRoute>}
                        />
                        <Route
                            path='/spare-parts'
                            element={<ProtectedRoute><SparePartsPage/></ProtectedRoute>}
                        />
                        <Route
                            path='my/machine/:alias/'
                            element={<ProtectedRoute><MachineDetails key={1} type={MachineTypes.MyMachine}/></ProtectedRoute>}
                        />
                        <Route
                            path='/machine/:alias/'
                            element={<ProtectedRoute><MachineDetails key={2} type={MachineTypes.StandardMachine}/></ProtectedRoute>}
                        />

                        <Route
                            path='/quick-order'
                            element={<ProtectedRoute><QuickOrder/></ProtectedRoute>}
                        />
                        <Route
                            path='/my-account/:tab'
                            element={<ProtectedRoute><AccountPage key={'my-account-tab'}/></ProtectedRoute>}
                        />
                      <Route
                            path='/my-account'
                            element={<ProtectedRoute><AccountTabs /></ProtectedRoute>}
                        />
                        <Route
                            path='/my-account/:tab/:id'
                            element={<ProtectedRoute><AccountPage key={'my-account-tab-id'}/></ProtectedRoute>}
                        />
                        {/*<Route*/}
                        {/*    path='/my-account/:tab/:alias'*/}
                        {/*    element={<ProtectedRoute><AccountPage/></ProtectedRoute>}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    path='/my-orders'*/}
                        {/*    element={<ProtectedRoute><OrderListPage/></ProtectedRoute>}*/}
                        {/*/>*/}
                        <Route
                            path='/cart'
                            element={<ProtectedRoute><CartPage/></ProtectedRoute>}
                        />
                        <Route
                            path='/checkout'
                            element={<ProtectedRoute><CheckoutPage/></ProtectedRoute>}
                        />
                        <Route
                            path='/order-completed'
                            element={<ProtectedRoute><OrderCompletedPage/></ProtectedRoute>}
                        />
                        {/*/!* Static Pages *!/*/}
                        <Route
                            path='/blog/:alias'
                            element={<StaticPage category={PageCategory.Article} />}
                        />
                        <Route
                            path='/page/:alias'
                            element={<StaticPage category={PageCategory.Page}/>}
                        />
                        <Route
                            path='/term/:alias'
                            element={<StaticPage category={PageCategory.Term}/>}
                        />
                        <Route
                            path='/blog'
                            element={<BlogPage/>}
                        />
                    </Routes>
                </BrowserRouter>
            </Suspense>
        </ThemeProvider>
    );
}

export default App;