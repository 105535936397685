import {Box, ListItemButton, Typography} from "@mui/material";
import {primaryColor} from "../../../../theme";
import SelectLanguage from "../selectLanguage";
import * as React from "react";
import {useState} from "react";
import {NavLink} from "react-router-dom";
import { accountMenu} from "../../../../data";
import {Translation} from "react-i18next";
import {logout} from "../../../../reducers/auth";
import store from "../../../../store";
import { setOpenedDrawerID} from "../../../../reducers/static";
import { MenuItem } from "../../../../interfaces/backend";
 

export const accountDrawerIcon = (open: boolean) => {
    return (
        <Box
            component='img'
            src={!open ? "/images/whiteAccount.svg" : "/images/account.svg"}
            sx={{
                height: {xs: '19px', md: '28px'},
                width: {xs: '15px', md: '22px'}
            }}
            alt={"image account"}
        />
    )
}

const ListItemSample = ({title, isActive}: { title: string; isActive: boolean }) => {
    const dispatch = store.dispatch
    const [selectOpen, setSelectOpen] = useState(false);
    const handleBoxClick = () => {
        setSelectOpen(!selectOpen);
    };
    const bgColor = !isActive ? primaryColor.drawerBackground : primaryColor.drawerHover;
    return <Translation ns='common'>
        {
            t => <ListItemButton
                sx={{
                    paddingLeft: '15%',
                    paddingRight: '30px',
                    backgroundColor: bgColor,
                    '&:hover': {
                        color: primaryColor.drawerText,
                        backgroundColor: primaryColor.drawerHover
                    },
                }}
                onClick={() => {
                    if (title === "logout") {
                        dispatch(logout());
                    } else if (title === "language") {
                        handleBoxClick();
                    } else {
                        dispatch(setOpenedDrawerID(""))
                        return null
                    }
                }}
            >
                {title !== 'language' ?
                    <Typography
                        variant='h6'
                        color={primaryColor.drawerText}
                    >
                        {title}
                    </Typography>
                    :
                    <Box width={'100%'} height={'18px'} display='flex' justifyContent={'start'} alignItems={'baseline'}>
                        <Typography
                            variant={'h6'}
                            color={primaryColor.drawerText}
                            width={'45%'}
                        >
                            {t('language')}:
                        </Typography>
                        <SelectLanguage mobileView={true} openState={selectOpen} onSelectOpen={handleBoxClick}/>
                    </Box>
                }
            </ListItemButton>
        }
    </Translation>
}

const StyledLink = ({to, title}: { to: string, title: string }) => {
    return (
        <NavLink to={to} style={{width: '100%'}}>
            {({isActive}) => (
                <ListItemSample
                    title={title} isActive={isActive}/>
            )}
        </NavLink>
    )
}

export const accountDrawerList: Array<JSX.Element> = accountMenu.map(item =>
    <StyledLink key={item.route} to={item.route} title={item.text}/>
)


export const normalDrawerList = (mainMenuLinks: MenuItem[]) => {
    
    const drawerMenu =[...mainMenuLinks, {link: "language", title: 'language'}];


      return drawerMenu.map(item => {
        
        return item.link !== 'language' 
        ? <StyledLink key={item.link} to={item.link} title={item.title}/>
        : <ListItemSample key={item.link} title={ item.title} isActive={false}/>
    });
};