import {Box, useMediaQuery} from "@mui/material";

import React from "react";
import {useAppSelector} from "../../hook";
import {SettingElement} from "../../interfaces/backend";
import {StyledTypography} from "../../components/common/StyledComponents";

import theme from "../../theme";

// interface invoicingCompanyAddress {
//     companyName: string,
//     street: string,
//     number: string,
//     postalCode: string,
//     city: string,
//     country: string | undefined
// }


interface InvoicingCompanyAddressProps {
    setShowInvoicingCompanyAddress: React.Dispatch<React.SetStateAction<boolean>>
}

export default function InvoicingCompanyAddress({setShowInvoicingCompanyAddress}: InvoicingCompanyAddressProps) {

    const countries = useAppSelector(state => state.metadata.countries)

    const settings: Array<SettingElement> = useAppSelector(state => state.staticContent.settings)

    const companyName = settings?.find(item => item.name === 'company_name')?.value
    const street = settings?.find(item => item.name === 'company_street_name')?.value
    const streetNumber = settings?.find(item => item.name === 'company_street_number')?.value
    const postalCode = settings?.find(item => item.name === 'company_postal_code')?.value
    const city = settings?.find(item => item.name === 'company_city')?.value
    const countryElement = settings?.find(item => item.name === 'company_country')?.value
    const country = countries.find(item => item.code === countryElement?.toUpperCase())?.name

    const mobileView = useMediaQuery(theme.breakpoints.down('md'))

    if (!(companyName && postalCode && city && country)) {
        setShowInvoicingCompanyAddress(false)
    }
    return (
        settings &&
        <Box
            sx={{
                marginTop: mobileView ? '0px' : '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: mobileView ? 'center' : 'unset'
            }}>
            <StyledTypography sx={{textTransform: 'uppercase'}}>{companyName}</StyledTypography>
            <StyledTypography>{street} {streetNumber}</StyledTypography>
            <StyledTypography>{postalCode} {city}</StyledTypography>
            <StyledTypography>{country}</StyledTypography>
        </Box>
    )
}

