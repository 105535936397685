import {Box, Grid} from "@mui/material";
import Logo from "../logo";
import {primaryColor} from "../../../../theme";
import * as React from "react";
import {normalDrawerList} from "./drawerListItem";
import LeftIcons from "./leftIcons";
import SelectLanguage from "../selectLanguage";
import {useAppSelector, useAppDispatch} from "../../../../hook";
import CustomDrawer from "../customDrawer";
import { MenuItem } from "../../../../interfaces/backend";
import { getMenu, setMainMenu } from "../../../../reducers/static";
import { useEffect } from "react";


export default function MobileView() {
    const token = useAppSelector(state => state.auth.token)
    const serviceUserEmail = useAppSelector((state) => state.auth.storedUserEmail);
  
    const dispatch = useAppDispatch()
    const mainMenuLinks: Array<MenuItem> = useAppSelector(state => state.staticContent.mainMenu)

    useEffect(() => {
      !mainMenuLinks.length && dispatch(getMenu({type: 'main_menu', handler: setMainMenu}))
  }, [dispatch, mainMenuLinks])
 

    return (
        <Grid container
              justifyContent='space-between'
              paddingTop='16px'
              paddingBottom='16px'
              paddingLeft='30px'
              paddingRight='30px'
              bgcolor={primaryColor.headerColor}
              zIndex={1300}
              position= 'fixed' 
              top={serviceUserEmail ? '75px' : '0'}
        >
            <Grid item xs={6}>
                <Box display={'flex'}
                     justifyContent='start'
                     alignItems='center'
                >
                    {token ?
                        <CustomDrawer
                            additionalButtonStyle={{paddingRight: '12px'}}
                            list={normalDrawerList(mainMenuLinks)}
                            id={"main-menu"}
                        />
                        : null}
                    <Logo mobileView={true}/>
                </Box>
            </Grid>
            <Grid
                item
                container
                justifyContent={'end'}
                alignItems='center'
                color={primaryColor.whiteText}
                xs={6}
            >
                {token ?
                    <Box height={'100%'}>
                        <LeftIcons/>
                    </Box>
                    :
                    <SelectLanguage mobileView={true}/>
                }

            </Grid>
        </Grid>

    )
}
