import React from 'react';

import {Box, Checkbox} from '@mui/material';

import {useAppDispatch, useAppSelector} from "../../hook";
import {checkTerm} from "../../reducers/cart";
import {FrontendTerm} from "../../interfaces/backend";
import theme, {primaryColor} from "../../theme";

interface TermProps {
    type?: string,
    term: FrontendTerm,
    index: number
}

const Term = (props: TermProps) => {
    const {type, term, index} = props
    const terms: Array<FrontendTerm> = useAppSelector(state => state.cart.terms)
    const dispatch = useAppDispatch()
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                [theme.breakpoints.down("md")]:{
                    alignItems: 'flex-start'
                }
            }}
        >
            {type === 'checkbox' && (
                <Checkbox
                    checked={terms[index].checked}
                    onChange={() => dispatch(checkTerm(index))}
                    sx={{
                        '&.MuiButtonBase-root.MuiCheckbox-root': {
                            paddingLeft: 'unset'
                        },
                        [theme.breakpoints.down("md")]: {
                            paddingTop: '5px'
                        }
                    }}
                />
            )}
            {term.labelLink ? (
                <Box
                    sx={{
                        color: primaryColor.whiteText,
                        fontSize: '1.6rem',
                        [theme.breakpoints.down("md")]:{
                            fontSize: '1.5rem',
                        }
                    }}
                >
                    {term.start}&nbsp;
                    <Box component={'a'}
                         target="_blank"
                         rel="noopener noreferrer"
                         href={term.useFile ? term.description : `/term/${term.alias}`}
                    >
                        {term.labelLink}
                    </Box>
                    &nbsp;{term.end}
                </Box>
            ) : (
                <Box>{term.label}</Box>
            )}
        </Box>
    );
};

export default (Term)

