import {Box, Grid, Typography, useMediaQuery} from "@mui/material"
import Page from "../page";
import theme, {primaryColor} from "../../theme";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {BreadCrumbsObject} from "../../interfaces/general";
import Paper from "@mui/material/Paper";
import {useParams} from "react-router-dom";
import Tab from "../../components/common/Tabs/Tab";
import AllTabs from "../../components/common/Tabs/AllTabs";
import {findItem, useAllMyAccountTabs, myAccountPageRelations} from "../../components/common/Tabs";
import { StyledPaper } from "../../components/common/StyledComponents";
 


export interface TabData {
  item: string,
  text: string,
  selected?: boolean,
  isLast?: boolean,
  isHeader?: boolean,
  setSelectedItem?: React.Dispatch<React.SetStateAction<string>>,
}

/*function findItem(arr: { item: string, text: string }[][], itemToFind?: string) {
  let res: { item: string, text: string }|undefined;
  arr.some(
      o => {
        res = o.find(
            of => of.item === itemToFind)
        return res
      }
  )
  return res;
}*/



const AccountPage = () => {
  const {t} = useTranslation(['myAccount']);
  const [selectedItem, setSelectedItem] = useState<string>("")
  const [selectedName, setSelectedName] = useState<string>()
  const params = useParams()

  const mobileView = useMediaQuery(theme.breakpoints.down("md"));
  const lgView = useMediaQuery(theme.breakpoints.down("lg"));
  const myAccountTabs = useAllMyAccountTabs()
  const makeBread = (): Array<BreadCrumbsObject> => {
    return [
      {name: t('common:home'), link: '/'},
      {name: t('common:myAccount'), link: '/my-account/my-data'},
    ]
  };

  useEffect(() => {

    if (params.tab) {
      setSelectedItem(params.tab)
    } else {
      setSelectedItem('my-data')

    }
  }, [])

  useEffect(() => {
    if (params.tab) {
      setSelectedItem(params.tab);
      const bottomHeader = document.getElementById("my-account-title");
      bottomHeader?.scrollIntoView({block: "start", inline: "nearest"})
      // if(mobileView)
      // {
        const tab = findItem(myAccountTabs, params.tab)
        if (tab) {
          setSelectedName(tab?.text)
        }
      // }
    }
  }, [params.tab])



  const getPage = (name: string) => {
    const result = myAccountPageRelations({id: params.id}).find(page => page.name === name)
    return result ? result.page : <></>
  }

  return (
      <Page breadCrumbs={makeBread()} matchToHeaderWidth={true}>
        {!mobileView ? <>
          <Box sx={{
          fontSize: {md: '5.5rem', lg: '7rem'},
          color: primaryColor.pageTitle,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          marginTop: '-5px',
          fontWeight: '700'
        }}
          id={"my-account-title"}
        >
          {lgView ? t('myAccount') : selectedName }
        </Box>
        <Box>
          <Grid container
              sx={{
                  margin: 'auto',
                  zIndex: 1300,
                  paddingTop: '25px',
                  paddingBottom: '80px'

                }}
              >
            <Grid key={1} xs={4} lg={3} item>
              <AllTabs allTabs={myAccountTabs} selectedItem={selectedItem} selectedName={selectedName} setSelectedName={setSelectedName} />
            </Grid>
            <Grid key={2} xs={8} lg={9} item>
            <StyledPaper >
                <Box>
                  {getPage(selectedItem)}
                </Box>
              </StyledPaper>
            </Grid>
          </Grid>
        </Box>
        </>:
        <>
          <Box sx={{color: primaryColor.myAccountTabText, height: '70px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Typography sx={{fontSize: '2.6rem', fontWeight: '600'}}>{t("myAccount")}</Typography>
          </Box>
          <Box mb={"25px"}>
            <Tab item={selectedItem} text={selectedName?selectedName:""} selected={true} isLast={true} isHeader={true}/>
          </Box>
          {getPage(selectedItem)}
        </>}
      </Page>
  )
}

export default (AccountPage)
