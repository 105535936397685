import React, {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from "../../hook";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {openErrorSnack} from "../../reducers/start";
import {uploadAttachments} from "../../reducers/cart";
import Dropzone, {FileRejection} from "react-dropzone";
import {PDF_EXTENSIONS} from "../../constants/extensions";
import CommonTooltip from "../../components/common/commonTooltip";
import UploadFilePreview from "./UploadFilePreview";
import {StyledTypography} from "../../components/common/StyledComponents";


import theme from "../../theme";

interface CheckoutDropzoneProps {
    showFilesInPreview: boolean,
    messageSent: boolean
}

const imageMaxSize = 3 * 1024 * 1024; // Rule: max 3MB/file

export default function CheckoutDropzone(props: CheckoutDropzoneProps) {

    const {showFilesInPreview, messageSent} = props

    const documents = useAppSelector(state => state.cart.checkoutCart.documents)

    const [state, setState] = useState({
        files: [],
        filesToPassToPreview: [],
        numberUploadedFiles: 0,
        filesToPassToMessage: [],
    })

    const {t} = useTranslation('checkoutPage')

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (messageSent) setState({...state, filesToPassToMessage: [], numberUploadedFiles: 0})
    }, [messageSent])

    useEffect(() => {
        !showFilesInPreview && setState({...state, filesToPassToPreview: []})
    }, [showFilesInPreview])

    let currentFileSize: number
    let currentFileName: string
    const verifyFile = (file: File) => {
        let message = '';

        if (file) {
            if (file.size && file.name) {
                currentFileSize = file.size
                currentFileName = file.name

                if (currentFileSize > imageMaxSize) {
                    message = t('maxSize')
                    dispatch(openErrorSnack({message: message}))
                    return false
                }
                if (
                    state.filesToPassToPreview.some((e: { name: string, size: number }) => e.name === currentFileName)
                ) {
                    message = t(
                        'FileIsAdded {{currentFileName}}',
                        {
                            currentFileName: currentFileName,
                        }
                    );
                    dispatch(openErrorSnack({message: message}))
                    return false
                }
            }
            return true
        }
    }

    const handleOnDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
        if (fileRejections && fileRejections.length > 0) {
            fileRejections.forEach((item) => {
                verifyFile(item.file)
                return item
            });
        }

        if (acceptedFiles && acceptedFiles.length > 0) {
            dispatch(uploadAttachments({attachedFiles: acceptedFiles}))
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: '32px',
                width: 'fit-content',
                [theme.breakpoints.down("md")]:{
                   padding: 'unset'
                }
            }}
        >
            {documents && documents.length &&
                <UploadFilePreview
                    filesToShow={documents}
                    canDelete={true}
                />
            }

            <Dropzone
                // disableClick
                multiple={true}
                maxSize={imageMaxSize}
                accept={PDF_EXTENSIONS}
                onDrop={handleOnDrop}
            >
                {({
                      getRootProps,
                      getInputProps
                  }) => (
                    <div>
                        <CommonTooltip
                            title={t('attachFile')}
                            placement={'top'}
                            enterDelay={300}
                            children={<></>}
                            leaveDelay={200}
                            interactive={false}
                            enterTouchDelay={100}
                            leaveTouchDelay={3000}
                            disableTouchListener={false}
                            element={
                                <div style={{cursor: 'pointer'}}>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Box sx = {{display: 'flex'}}>
                                            <Box
                                                component={'img'}
                                                alt='Attach file'
                                                src={'/images/icons/attachFileIcon.svg'}
                                                sx={{
                                                    width: '17px',
                                                    mr: '15px'
                                                }}
                                            />
                                            <StyledTypography>{t('addAttachment')}</StyledTypography>
                                        </Box>

                                    </div>
                                </div>
                            }
                        />
                    </div>
                )}
            </Dropzone>
        </Box>
    )
}


