import Box from "@mui/material/Box";
import ShippingAddressForm from "./ShippingAddressForm";
import ShippingAddressCardList from "./ShippingAddressCardList";

interface ShippingAddressCompProps {
  isMyAccountPage?: boolean;
}

export default function ShippingAddressComp(props: ShippingAddressCompProps) {
  const {isMyAccountPage} = props;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ShippingAddressCardList isMyAccountPage={isMyAccountPage}/>
      <ShippingAddressForm isMyAccountPage={isMyAccountPage}/>
    </Box>
  )
}