import React from 'react';

import Tooltip, {TooltipProps} from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

interface CommonTooltipProps extends TooltipProps {
  element: ReactJSXElement,
  interactive: boolean,
}

export default function CommonTooltip (props: CommonTooltipProps) {
  const {
    title,
    placement,
    enterDelay,
    leaveDelay,
    interactive,
      element,
    TransitionComponent,
    enterTouchDelay,
    leaveTouchDelay,
    disableTouchListener,
  } = props;

  return (
    <Tooltip
      title={title}
      placement={placement ? placement : 'top'}
      enterDelay={enterDelay ? enterDelay : 300}
      leaveDelay={leaveDelay ? leaveDelay : 200}
      disableInteractive={interactive !== undefined ? interactive : undefined}
      enterTouchDelay={enterTouchDelay ? enterTouchDelay : 100}
      leaveTouchDelay={leaveTouchDelay ? leaveTouchDelay : 3000}
      disableTouchListener={disableTouchListener ? disableTouchListener : false}
      TransitionComponent={TransitionComponent ? TransitionComponent : Fade}
    >
      {element}
    </Tooltip>
  );
};
