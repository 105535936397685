import { Box, Typography } from "@mui/material";
import { defaultFonts, primaryColor } from "../../../../theme";
import React from "react";
import { useAppDispatch } from "../../../../hook";
import { setSelectedTreeNode } from "../../../../reducers/sparePart";
import { SparePartsTreeSelectedNode } from "../../../../interfaces/backend";
import { StyledTreeItem } from "../styledSparePartItems";
import { useTranslation } from "react-i18next";

interface CustomTreeItemProps  {
  parentSpareParts: SparePartsTreeSelectedNode[];
}


const CustomTreeItem = (props: CustomTreeItemProps)  => {

  const {parentSpareParts} = props;

  const { t } = useTranslation("spareParts");
  const dispatch = useAppDispatch()
  const handleClick = (details: SparePartsTreeSelectedNode) => {
     dispatch(setSelectedTreeNode(details))
  };

  
  const renderLabel = (sparePart: SparePartsTreeSelectedNode) => {
    
    return (
      <Box
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (!sparePart.product.isMachine || !sparePart.groupChildren?.length) {
          handleClick(sparePart);
        }
      }}
        sx={{
          maxWidth: "fit-content",
          fontSize: defaultFonts,  
        }}>
             { sparePart.product.name }
      </Box>

    );
  };

 
const renderStyledTreeItem = (sparePart: SparePartsTreeSelectedNode) => {
  
  
  const noSpareParts = Array.isArray(sparePart.nonGroupChildren) 
  && !sparePart.nonGroupChildren.length 
  && sparePart.product.isMachine 
  && !sparePart.groupChildren.length

  const hasChildren =  sparePart.groupChildren.length > 0;
  
    return (
      <>
      {noSpareParts 
      ? <Typography sx={{color: primaryColor.sparePartsText}}>{t("noSparePartsFound")}</Typography> 
      : 
      <StyledTreeItem
        nodeId={
          sparePart.product.isMachine ? 'root-parent' :
          `${sparePart.product.alias}-${sparePart.serialNumber}`}
        label={renderLabel(sparePart)}
        onClick={() => {
          if (!hasChildren) {
            handleClick(sparePart);
          }
        }}
        
       sx={{ 
  
        backgroundColor: 'transparent',
        
        "& .MuiTreeItem-content, .MuiTreeItem-content.Mui-selected, .MuiTreeItem-content.Mui-expanded, .MuiTreeItem-content.Mui-expanded.Mui-selected, .MuiTreeItem-content.Mui-selected.Mui-focused": {
          
          backgroundColor:
          sparePart.level <= 1
          ? primaryColor.sparePartsTreeParentBackground
          : primaryColor.sparePartsTreeBackground,
          marginBottom: sparePart.level <= 1 ? '5px' : 0,
          
          borderBottom:  sparePart.level <= 1 ? null :  `0.8px solid ${primaryColor.sparePartsTreeBorderBottom}`,
        },
   
        "& .MuiTreeItem-root, .MuiTreeItem-content.Mui-expanded.Mui-selected": {

          backgroundColor: 
          sparePart.level <= 1
          ? primaryColor.sparePartsTreeParentBackground
          : primaryColor.sparePartsTreeExpandedChildBackgroundColor

        },

        "& .MuiTreeItem-label":{
          fontSize: defaultFonts,
        },

      }}
      >
        {  hasChildren ?
          <CustomTreeItem parentSpareParts={sparePart.groupChildren}  />
        : null
        }
      </StyledTreeItem>
    }
    </>

    );
  };
  return (
    <>
      {Array.isArray(parentSpareParts) &&
        parentSpareParts.map((sparePart) => (
          <React.Fragment key={`${sparePart.product.alias}-${sparePart.serialNumber}`}
            
            >
            {renderStyledTreeItem(sparePart)}
          </React.Fragment>
        ))}
    </>
  );
};

export default CustomTreeItem;